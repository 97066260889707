import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import i18n from 'i18next';
import { SUB_ADMIN_TYPES } from "assets/constants";

const ProtectedRoute = ({isAdmin = false,isSubAdmin=false,auth=false}) => {
    const {pathname} = useLocation()
    const {user} = useSelector((store) => store.user);
    const {adminUser} = useSelector((store) => store.admin)
    if(auth)
    {
        if(!user || !adminUser)
        {
            return user || adminUser ? <Outlet/> : <Navigate to={`/${i18n.language}/login`} state={{from: pathname}}/>
  
        }
    }
    else if (isAdmin)
    {
        return adminUser?.type === "owner"  ? <Outlet/> : <Navigate to={`/${i18n.language}/admin-login`} state={{from: pathname}}/>
    }
    else if(isSubAdmin)
    {
        return SUB_ADMIN_TYPES.includes(adminUser?.type) ? <Outlet/> : <Navigate to={`/${i18n.language}/admin-login`} state={{from: pathname}}/>
    }
    else {
        if(user){
                return user ? <Outlet/> : <Navigate to={`/${i18n.language}/login`} state={{from: pathname}}/>
        }
        else{
            toast.error('Register yourself first ...', {toastId: 123})
             
            return <Navigate to={'/'}/>
        }
        
}
}

export default ProtectedRoute

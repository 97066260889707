import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyALBlJIjv-7j9S9skD92BEnnmv3ThmPlz0",
  authDomain: "javatimescaffe-46f80.firebaseapp.com",
  projectId: "javatimescaffe-46f80",
  storageBucket: "javatimescaffe-46f80.appspot.com",
  messagingSenderId: "720474652082",
  appId: "1:720474652082:web:3fe734336c7303b70478ab",
  measurementId: "G-C8B7XG14KM"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

import React from "react";
// import { Link } from "react-router-dom";
import Link from 'hooks/Link';
import cmsImage from "utils/cmsImage";
import detectLang from "utils/detectLang";
import _html from "utils/_html";

const TeamCard = ({ data,lang }) => {
  return (
    <>
      <div className="t-card-item p-3 mx-auto">
        <div className="t-card-wrapper ">
          <div className="t-card-img mb-3">
            <img src={cmsImage(data?.image)} alt="" />
          </div>
          <p className="card-title">{detectLang(data,'name',lang)}</p>
          <p className="normal-p team-title mb-0">{detectLang(data,'designation',lang)}</p>
          <p className="t-card-desc limit mb-1">{_html(detectLang(data,'description',lang))}</p>
          <Link to={`/team/${data?.id}`} className="read-more-btn">
            Read more...
          </Link>
        </div>
      </div>
    </>
  );
};

export default TeamCard;

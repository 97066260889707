import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useNavigate from "hooks/Navigate";
const SignUpConditionModal = ({ status, toggleModal, setState }) => {
  const navigate = useNavigate();
  const { language } = useSelector(store => store.language)
  const { t } = useTranslation();
const submitHandler=()=>{
  setState(true)
  toggleModal()
}
  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Java Times Caffe Terms And Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '12px' }}>
        <Col style={{ fontSize: '14px' }}>Dear Investor:</Col>
        <br />
        <Col>Condiciones for the registration accepting to be over 18 years old</Col>
        <br />
        <Col>Edad: El Socio Inversionista debe tener al menos 18 años de edad. La mayoría de edad es un requisito legal para entrar en contratos vinculantes, incluyendo los acuerdos de inversión.</Col>
        <br />
        <Col>Verificación de Edad: Para confirmar que el Socio Inversionista cumple con el requisito de edad, debe presentar una copia legible y válida de su acta de nacimiento.</Col>
        <br />
        <Col>Consentimiento: Al aceptar estos términos y condiciones, el Socio Inversionista declara y garantiza que es mayor de 18 años y que tiene pleno derecho, capacidad y autoridad para entrar en este acuerdo.</Col>
        <br />
        <Col>Infracciones: Si se descubre que el Socio Inversionista ha tergiversado su edad, el acuerdo de inversión podrá ser rescindido inmediatamente y todas las inversiones serán devueltas sin rendimientos.</Col>
        <br />
        <Col>Jurisdicción: Estos términos y condiciones se rigen por las leyes del país en el que se encuentra la entidad de inversión. Las partes se someten a la jurisdicción de los tribunales de dicho país.</Col>
        <br />

        <div className='Login-btn-container mb-3' >

          <button type='submit' onClick={submitHandler} >
            Agree
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignUpConditionModal;

import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import apis from 'services'; 

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

function DocumentVerificationCard({ type, data, value ,getUserDocumentData,id }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [document, setDocument] = useState({});
  const [formData, setFormData] = useState({ type: value, document: '' });
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((store) => store.user);



  const submitDocument = async (type) => {
    try {
      setLoading(true);
      const response = await apis.verifyUserDocuments(data?._id, type);
      if (response?.data?.status) {
        setDocument(response?.data?.document);
        toast.success(response?.data?.message);
        getUserDocumentData(id)
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
      console.error(e?.message);
    }
  };

  const getData = () => {
    setDocument(data);
  };

  useEffect(() => {
    getData();
  }, []);



  const handleSubmitClick = (type) => {
    submitDocument(type);
  };

  // Function to check if a file is a PDF
  const isPDF = (url) => {
    return url.toLowerCase().endsWith('.pdf');
  };


  return (
    <div className="doc-card" style={{ position: 'relative', width: '350px' }}>
      <span className={`doc-card-status ${document?.status === "approved" ? "accepted" : document?.status === "pending" ? "pending" : "rejected"}`}>
        {document?.status === "approved" ? "APPROVED" : document?.status === "pending" ? "Pending" : "Rejected"}
      </span>
      <div className='doc-card-wrap'>
        <div className="profile-nft-row1">
          <div className="NFT-image-holder">

            {document?.document ? (
              isPDF(document.document) ? (
                // Display PDF
                <iframe src={document.document} width="100%" height="250" title="Document PDF" />

              ) : (
                // Display image
                <img src={document.document} style={{ height: 250 }} alt="Uploaded" />
              )
            ) : (
              <img src='/images/doc-dummy-image.png' style={{ height: 250 }} alt="" />
            )}
          </div>
          <div>

            {document?.status === "pending" ?
              <div className="box-verified justify-content-around">

                <Button
                  variant={"success"}
                  onClick={() => handleSubmitClick("approved")}
                >
                  Approved
                </Button>
                <Button
                  variant={"danger"}
                  onClick={() => handleSubmitClick("rejected")}
                >
                  Rejected
                </Button>

              </div>

              : document?.status === "approved" ?

                <div className="NFT-image-upload-txt justify-content-around">

                  <p>
                    Approved
                  </p>
                </div>


                :

                <div className="NFT-image-upload-txt justify-content-around">
                  <p
                  >
                    Rejected
                  </p>

                </div>

            }
            <p className='footer-descc'>{type}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentVerificationCard;

import AdminNewSidebar from "components/admin/AdminNewSidebar";
import React, { useEffect,useState } from "react";
import { Box, Container } from "@mui/material";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import { Table,Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import apis from "../../services";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAdmin } from "redux/slices/adminSlice";

import useNavigate from "hooks/Navigate";
export default function AdminInvest() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)
  const [stores, setStores] = useState([]);



  let {id} = useParams();


  const [data, setData] = useState({
    id:"",
    shareAmount:'',
    totalShares:''
  });

  const onChangeHandler = (e) => {
    const { id, value } = e.currentTarget;
    setData((prevState) => ({ ...prevState, [id]: value/20}));
  };

  const fetchAllStores = async () => {
    const res = await apis.getAllActiveStores();
    setStores(res?.data?.stores);
};

useEffect(() => {
  (async () => {
      await fetchAllStores();
  })();
}, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
if(data?.id)
{
  setLoader(true);
  const response = await apis.adminInvestUser(id,data);;
  if (response.status === 201) {
    dispatch(setAdmin(response.data?.user))
      toast.success(response.data.message);
          setTimeout(() => navigate("/admin/users"), 2000);
  }

}
else{

  toast.error("Please select the store");
  
}  } catch (e) {
        console.error(e);
        toast.error(e.message)
        setLoader(false);
    }

}

  
  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="sm" sx={{ mt: 0, mb: 4 }}>
              <div className="bg-light p-5">
                <h3 className=""> User Investment </h3>
                <Form onSubmit={submitHandler}>
                  <Form.Group className="mb-3 pt-3" controlId="formGroupEmail">
                    <Form.Label>Investment Amount</Form.Label>
                    <Form.Control
                      type="number"
                      id="shareAmount"
                      min={30}
                      placeholder="Enter Investment Amount"
                      onChange={onChangeHandler}
                      required
                    />
                  </Form.Group>

                  <Form.Label>Select Store</Form.Label>

                  <div style={{overflow: "auto", maxHeight: "150px"}}>

<Table bordered hover size="sm">
  <thead>
    <tr>
      <th>Image</th>
      <th>Name</th>
      <th>Shares</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {stores.length > 0 ? (
      stores.filter((item)=>item.active===true).map((item, i) => {
        return (
          <>
            <tr key={item.id} style={{ textAlign: 'center'}}>
              <td> <img src={item?.image} width={"41px"} height={"41px"} alt="image" /></td>
              <td>{item?.title}</td>
              <td>{item?.totalShares}</td>
              <td><input  style={{ marginTop : '12px'}} type="checkbox" onClick={() => setData((prevState)=>({...prevState,id:item?._id , totalShares:item?.totalShares }))} checked={item?._id === data?.id} ></input></td>
            </tr>
          </>
        )
      })
    ) : (
      <tr style={{ textAlign: 'center' }} >
        <td colSpan={7}>No Stores</td>
      </tr>
    )}

  </tbody>
</Table>

</div>


                  <div className="m-3">
                 <Button className=' mx-auto' variant="outline-danger"
                                                type='submit'
                                                disabled={loader}> {loader ? t("buttons.submitting") : t("buttons.submit")}
                                        </Button>
                  </div>
                </Form>
              </div>
            </Container>
          </Box>
        </div>
      </Box>
    </>
  );
}

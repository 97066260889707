import React, { useEffect, useState } from "react";
import PersonItem from "./PersonItem";
import Slider from "react-slick";
import apis from "services";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

const InvestorSlider = ({ className }) => {
  const settings = {
    autoplay: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    swipeToSlide: true,
    // arrows:false,
    nextArrow: <MdKeyboardArrowRight/>,
    prevArrow: <MdKeyboardArrowLeft/>,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 592,
        settings: {
          slidesToShow: 1,
        },
      },
    ],

    // nextArrow: <PrimaryNextArrow />,
    // prevArrow: <PrimaryPrevArrow />,
  };

  const [data, setData] = useState([]);

  const fetchData = async () => {
    const res = await apis.getRecentUserInvest();
    setData(res?.data?.recentUser);
  };
  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);


  return (
    <>
      <div className={className}>
        <Slider {...settings}>
          {data?.length > 2 ? (
            data?.map((item, index) => {
              return (
                <>
                  {" "}
                  <PersonItem key={index} data={item} />
                </>
              );
            })
          ) : (
            <></>
          )}
        </Slider>
      </div>
    </>
  );
};

export default InvestorSlider;

import React, { Fragment, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import MailIcon from "@mui/icons-material/Mail";
import { useDispatch, useSelector } from "react-redux";
import Link from "hooks/Link";

import useNavigate from "hooks/Navigate";
import apis from "services";
import { Paid } from "@mui/icons-material";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import imageUrl from "utils/imageUrl";
import { Header } from "components";
import DashboardChart from "components/DashboardChart";
import { useTranslation } from "react-i18next";
import utils from "../../utils";
import {
  ADMIN_TYPES,
  SUB_ADMIN_TYPES,
  TOTAL_SHARES,
} from "../../assets/constants";
import { setAdmin } from "redux/slices/adminSlice";
import { getToken } from "firebase/messaging";
import { firebaseApp, messaging } from "../../firebase";

export const mainListItems = (
  <Fragment>
    <Link to={"/admin/dashboard"} style={{ textDecoration: "none" }}>
      <ListItemButton
        // autoFocus={true}
        className="justify-content-center mt-3 active"
      >
        <ListItemIcon>
          <DashboardIcon sx={{ color: "#fff" }} />
        </ListItemIcon>
        <ListItemText className="text-white" primary="Dashboard" />
      </ListItemButton>
    </Link>

    <Link to={"/admin/users"} style={{ textDecoration: "none" }}>
      <ListItemButton className="justify-content-center mt-3">
        <ListItemIcon>
          <PeopleIcon sx={{ color: "#fff" }} />
        </ListItemIcon>
        <ListItemText className="text-white" primary="Users" />
      </ListItemButton>
    </Link>

    <Link to={"/admin/stores"} style={{ textDecoration: "none" }}>
      <ListItemButton className="justify-content-center mt-3">
        <ListItemIcon sx={{ color: "#fff" }}>
          <MailIcon />
        </ListItemIcon>
        <ListItemText className="text-white" primary="Stores" />
      </ListItemButton>
    </Link>

    <Link to={"/admin/transactions"} style={{ textDecoration: "none" }}>
      <ListItemButton className="justify-content-center mt-3">
        <ListItemIcon sx={{ color: "#fff" }}>
          <Paid />
        </ListItemIcon>
        <ListItemText className="text-white" primary="Transactions" />
      </ListItemButton>
    </Link>
  </Fragment>
);

const mdTheme = createTheme();

const AdminDashboard = () => {
  const adminData = useSelector((store) => store.admin);
  const admin = adminData?.adminUser;
  const { t } = useTranslation();
  const navigate = useNavigate();
const dispatch = useDispatch()
  const { adminUser } = useSelector((store) => store.admin);
  const [recordData, setRecordData] = useState({ users: "", stores: "" });

  //Charts Api
  const [charData, setChartData] = useState();

  
  const [showDropdown, setShowDropdown] = useState(false);
  const fetchChartData = async () => {
    const res = await apis.getChartData();
    if(res?.data)
    {
      setChartData(res?.data?.yearly_data);
      setRecordData(res?.data?.data);
   
    }
    };

  //Analytic
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const res = await apis.getTotalRecord();
    if(res?.data)
    {
      setData(res?.data);
 
    }
  };
  const fetchAdminProfile = async()=>{
    const response =  await apis.getAdminProfile(admin?._id);
    if(response?.data?.status)
    {
      dispatch(setAdmin(response?.data?.admin))
    }
  }

  const fetchToken = async () => {
    return getToken(messaging, { vapidKey: 'BMpVypoBuKIlz3lr5Mal3KlJY2ozYfIGPBS1Hy0wwLYCh4a-XaVx0uRhKj0-a7YBtSO--qZyAQgiLWg62avaM1k' }).then(async (currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            localStorage.setItem("not_token", currentToken)
            if (adminUser) {
                const res = await apis.updateAdminProfileToken(adminUser?._id, { not_token: currentToken })
                if (res?.data?.status) {
                    dispatch(setAdmin(res?.data?.admin))
                }
            }


        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}


  useEffect(() => {
    (async () => {
      await fetchData();
      await fetchChartData();
     await fetchAdminProfile();
     await fetchToken();
    })();
  }, []);
  
  return (
    <ThemeProvider theme={mdTheme}>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header showDropdown={showDropdown} setShowDropdown={setShowDropdown} />
            <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
              <h3 className="dashboard-title">{t("titles.dashboard")}</h3>
              <Grid container className="mt-4 pt-1 db-container">
                <div className="custom-box row">
                  {utils.checkAdminType() === ADMIN_TYPES.ADMIN ? (
                    <>
                      {/* USER BOX */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">
                              {t("labels.admin_dashboard_1")}
                            </h3>
                            <h4 className="">{recordData?.users}</h4>
                          </div>

                          <h6
                            className="mt-5"
                            onClick={() => {
                              navigate("/admin/users");
                            }}
                          >
                            Report
                          </h6>

                          <img src={imageUrl("d_users.png")} />
                        </Paper>
                      </Grid>

                      {/* TOTAL SHARES BOX */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">{t("labels.real_share_Ceo")}</h3>
                            <h6 className="">
                              {admin?.wallet?.shares.toFixed(2) || 0}
                            </h6>
                          </div>
                          <h6
                            className="mt-5"
                            onClick={() => {
                              navigate("/admin/share-transfer");
                            }}
                          >
                            Donation
                          </h6>

                          <img src={imageUrl("d_shares.png")} />
                        </Paper>
                      </Grid>

                      {/*"TOTAL AVAILABLE SHARES"*/}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">
                              {t("labels.admin_dashboard_3")}
                            </h3>
                            <h4 className="">
                              {data?.totalAvailableShares || 0}
                            </h4>
                          </div>

                          <img src={imageUrl("d_available_shares.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">
                              {t("labels.employee_share_reserve")}
                            </h3>
                            <h6 className="">
                              {admin?.reserveWallet?.shares || 0}
                            </h6>
                          </div>
                          <h6
                            className="mt-5 mx-2"
                            onClick={() => {
                              navigate("/admin/share-transfer-history");
                            }}
                          >
                            Transfer Report
                          </h6>

                          <img src={imageUrl("d_available_shares.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">
                              {t("labels.admin_dashboard_4")}
                            </h3>
                            <h4 className="">{recordData?.stores || 0}</h4>
                          </div>

                          <h6
                            className="mt-5"
                            onClick={() => {
                              navigate("/admin/stores");
                            }}
                          >
                            Report
                          </h6>

                          <img src={imageUrl("d_stores.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">Total Funded Store Amount</h3>
                            <h4 className="">
                              {data?.totalStoreFundedAmount || 0}
                            </h4>
                          </div>

                          <h6
                            className="mt-5"
                            onClick={() => {
                              navigate("/admin/transactions");
                            }}
                          >
                            Report
                          </h6>

                          <img src={imageUrl("d_profit.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">Share Per Price</h3>
                            <h4 className="">
                              {data?.TOTAL_PRICE_PER_SHARE || 20}
                            </h4>
                          </div>

                          <img src={imageUrl("d_profit.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">Total Investor</h3>
                            <h4 className="">{data?.totalInvestor || 0}</h4>
                          </div>
                          <h6
                            className="mt-5"
                            onClick={() => {
                              navigate("/admin/users");
                            }}
                          >
                            Report
                          </h6>

                          <img src={imageUrl("d_users.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">
                              {t("labels.admin_dashboard_5")}
                            </h3>
                            <h4 className="">{admin?.wallet?.dividend || 0}</h4>
                          </div>
                          <h6
                            className="my-5 mx-4"
                            onClick={() => {
                              navigate("/admin/transactions");
                            }}
                          >
                            Report
                          </h6>

                          <img src={imageUrl("d_profit.png")} />
                        </Paper>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {SUB_ADMIN_TYPES.includes(adminUser?.type) && (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">
                              {t("labels.admin_dashboard_1")}
                            </h3>
                            <h4 className="">{recordData?.users}</h4>
                          </div>

                          <h6
                            className="mt-5"
                            onClick={() => {
                              navigate("/sub-admin/users");
                            }}
                          >
                            Report
                          </h6>

                          <img src={imageUrl("d_users.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">
                              {t("labels.admin_dashboard_4")}
                            </h3>
                            <h4 className="">{recordData?.stores || 0}</h4>
                          </div>

                          <h6
                            className="mt-5"
                            onClick={() => {
                              navigate("/sub-admin/stores");
                            }}
                          >
                            Report
                          </h6>

                          <img src={imageUrl("d_stores.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">Total Funded Store Amount</h3>
                            <h4 className="">
                              {data?.totalStoreFundedAmount || 0}
                            </h4>
                          </div>

                          <h6
                            className="mt-5"
                            // onClick={() => {
                            //   navigate("/sub-admin/transactions");
                            // }}
                          >
                            Report
                          </h6>

                          <img src={imageUrl("d_profit.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">
                              {t("labels.admin_dashboard_3")}
                            </h3>
                            <h4 className="">
                              {data?.totalAvailableShares || 0}
                            </h4>
                          </div>

                          <img src={imageUrl("d_available_shares.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">Share Per Price</h3>
                            <h4 className="">
                              {data?.TOTAL_PRICE_PER_SHARE || 20}
                            </h4>
                          </div>

                          <img src={imageUrl("d_profit.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">Total Investor</h3>
                            <h4 className="">{data?.totalInvestor || 0}</h4>
                          </div>
                          <h6
                            className="mt-5"
                            onClick={() => {
                              navigate("/sub-admin/users");
                            }}
                          >
                            Report
                          </h6>

                          <img src={imageUrl("d_users.png")} />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper className="dashboard-box">
                          <div className="text">
                            <h3 className="">
                              {t("labels.admin_dashboard_5")}
                            </h3>
                            <h4 className="">{adminUser?.wallet?.dividend || 0}</h4>
                          </div>
                        </Paper>
                      </Grid>
                    </>
                  )}
                </div>
                {/* Chart */}
                <Grid item xs={12} md={12} lg={12} className="db-chart">
                  <Paper>
                    <div className="mt-5">
                      {/* <DashboardChart data={charData} /> */}
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default AdminDashboard;

import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Paper } from "@mui/material";
import apis from "services";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import useNavigate from "hooks/Navigate";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector,useDispatch } from "react-redux";
import { setWallet } from "redux/slices/walletSlicer";
import InvestOptionsModal from "components/modals/InvestOptionsModal";
import RequestWithDrawlTable from "components/datagrid/RequestWithDrawlTable";
import { setAdmin } from "redux/slices/adminSlice";

const WithDrawlRequest = () => {
    
  const {adminUser} = useSelector((store) => store.admin);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(()=>{
    if(adminUser)
    {
      setData((prevState)=>({...prevState,type:"owner",balance:adminUser?.wallet?.dividend || 0}))
    }
  },[adminUser])


const onSelect = (e) => {
  const type = e.target.value;
  const balances = {
    'owner': adminUser?.wallet?.dividend || 0,
    'reserve': adminUser?.reserveWallet?.dividend || 0,
    'owner-new': adminUser?.newWallet?.dividend || 0,
  };
  setData(prevState => ({...prevState, type, balance: balances[type]}));
}

  const [withDrawl, setWithDrawl] = useState([]);

  const [data, setData] = useState({
    amount: "",
    balance:0,
    type:"owner",
  });
  
  const fetchAllWithDrawladmin = async () => {
    const res = await apis.getAllWithdrawlAdmin();
    setWithDrawl(res?.data?.withDrawl);
  };

  
  useEffect(() => {
    (async () => {
      await fetchAllWithDrawladmin();
    })();
  }, []);

  const { rows, columns } = RequestWithDrawlTable(withDrawl);
  const onChangeHandler = (e) => {
    const {name, value} = e.target;
    setData((prevState) => ({...prevState, [name]: value}));
};


const submit = async (e) => {
    e.preventDefault();
  const res = await apis.addWithdrawlAdmin(data);
  if (res?.data?.status) {   

    dispatch(setAdmin(res.data?.wallet))
    toast.success(res.data?.message);
    setData((prev)=>({...prev,amount:""}))
    await fetchAllWithDrawladmin();
  } else
  {
      toast.error(res.data?.message);
      setData((prev)=>({...prev,amount:""}))
  }
  };

  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
              {/* <div className="flex-with-title">
                <h3 className="dashboard-title">{t("titles.withDrawl-admin")}</h3>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <Paper
                  style={{width:"100%"}}
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="dashboard-box"
                  >
                    <h3 className="text-center">{t("labels.balance")}</h3>
                    <h4>{data?.balance?.toFixed(3)}</h4>
                  </Paper>
                </Grid>
              </div> */}
<div class="container-withdrawl">
<Grid xs={12} sm={12} md={12} lg={12}>
                  <Paper
                  style={{width:"100%"}}
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="dashboard-box"
                  >
                    <h3 className="text-center balance-withdrawl">{t("labels.balance")}</h3>
                    <h4 className="text-center balance-withdrawl">{data?.balance?.toFixed(3)}</h4>
                  </Paper>
                </Grid>
  <div class="content-withdrawl">
                <h3 className="dashboard-title">{t("titles.withDrawl-admin")}</h3>
  </div>
</div>
              <Form onSubmit={submit} className="mx-5">
                <Row className="d-flex justify-content-center align-items-center">
                  <Col xs={12} md={12} lg={4}>
                    <Form.Group className="mb-2" controlId="title">
                      <Form.Label>Enter Amount</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="amount"
                        placeholder="Enter request withdrawl amount"
                        onChange={onChangeHandler}
                        value={data.amount}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="type">
                            <Form.Label>Wallet Type</Form.Label>
                            <Form.Select id='type' onChange={onSelect} required value={data.type}>
                                <option disabled>Select Wallet Balance Type</option>
                                <option value={"owner"} >Normal Wallet</option>
                                <option value={"reserve"}>Reserve Wallet</option>    
                                <option value={"owner-new"}>New Wallet</option>    
                           
                            </Form.Select>
                        </Form.Group>
                        <div  className="text-center"
                     >
                        <Button
                      className="lp-btn mt-4 text-center"
                      variant="outline-danger"
                      type="submit"
                    >
                      {" "}
                      Submit
                    </Button>
                    </div>
                       
                  </Col>
                  {/* <Col xs={12} md={12} lg={12}>
                    <Button
                      className="lp-btn mt-4"
                      variant="outline-danger"
                      type="submit"
                    >
                      {" "}
                      Submit
                    </Button>
                  </Col> */}
                </Row>
              </Form>
              <Grid
                item
                container
                justifyContent="flex-end"
                className="mt-4"
                sx={{ height: "600px" }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              </Grid>
            </Container>
          </Box>
        </div>
      </Box>
     </>
  );
};

export default WithDrawlRequest;


import { useTranslation } from "react-i18next";
import React from "react";
import { Col, Row } from "react-bootstrap";
import utils from "utils";
import imageUrl from "utils/imageUrl";

const PersonItem = ({ data }) => {
  const diffrenceDate = (date) => {
    const date1 = new Date();
    const date2 = new Date(date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + " days";
  };

  const { language, setLanguage, t } = useTranslation();

  return (
    <>
      <div className="person-item-wrapper">
        <div className="person-item">
          <div className="left">
            <div className="rounded-square-img">
              <img src={utils.serverImg(data?.userId?.image)}
                onError={(e) =>
                  (e.currentTarget.src = imageUrl("placeholder.png"))
                } alt="" />
            </div>
          </div>
          <div className="right">
            <h3>
            {data?.userId?.nickName.includes('@') ?
                data?.userId?.nickName.split('@')[0] :
                data?.userId?.nickName
              }
            </h3>
            <p className="person-item-desc">
              {t("words.invested")}{" "}
              {t("words.in")} &nbsp;
              {data?.storeId?.title}
            </p>
            <p className="person-item-days">
              <span>${data?.amountInvested}</span>
              <span>{diffrenceDate(data?.purchasedAt)} {t("words.ago")}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonItem;

import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import apis from 'services'; // Make sure you have the appropriate service functions for submitting documents

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function DocumentCard({ type, data, value ,getDocumentData }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [document, setDocument] = useState({});
  const [formData, setFormData] = useState({ type: value, document: '' });
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((store) => store.user);

  let allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Check if the selected file is of an allowed file type
    if (file && allowedFileTypes.includes(file.type)) {
      setSelectedImage(URL.createObjectURL(file));
      setFormData((prevState) => ({ ...prevState, type: value, document: file }));
    } else {
      toast.error('Only PDF, JPG, PNG, and JPEG files are allowed.');
    }
  };
  const submitDocument = async () => {
    try {
      const form_data = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        form_data.append(key, value);
      }
      setLoading(true);
      const response = await apis.submitUserDocuments(user?.id, form_data);
      if (response?.data?.status) {
        setDocument(response?.data?.document);
        toast.success(response?.data?.message);
        getDocumentData()
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
      console.error(e?.message);
    }
  };

  const getData = () => {
    data.map((item) => {
      if (item?.type === value) {
        setDocument(item);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (formData.document) {
      submitDocument();
    }
  }, [formData]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Function to check if a file is a PDF
  const isPDF = (url) => {
    return url.toLowerCase().endsWith('.pdf');
  };


  return (
    <div className="doc-card" style={{ position: 'relative' }}>
      <span className={`doc-card-status ${document?.status === "approved" ? "accepted" : document?.status === "pending" ? "pending" : document.status ==="rejected" ?  "rejected":"processing"}`}>
        {document?.status === "approved" ? "APPROVED" : document?.status === "pending" ? "Processing" : document?.status === "rejected" ? "Rejected" : "Pending"}
      </span>
      <div className='doc-card-wrap'>
        <div className="profile-nft-row1">
          <div className="NFT-image-holder">
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            {document?.document ? (
              isPDF(document.document) ? (
                // Display PDF
                <iframe src={document.document} width="100%" height="250" title="Document PDF" />
            
              ) : (
                // Display image
                <img src={document.document} style={{ height: 250}}  alt="Uploaded" />
              )
            ) : (
              <img src='/images/doc-dummy-image.png' style={{ height: 250 }} alt="" />
            )}
          </div>
          <div>
            <div className="NFT-image-upload-txt">
              <button onClick={handleButtonClick} disabled={document?.status === "approved" || document?.status === "pending"} className="button-styling browse-btn" >
                <FiUpload />
                {document?.status === "approved" ? " Completed" : document?.status === "pending" ? " Processing" : " Upload"}
              </button>
              <p>
                Jpg & PDF 200kb
              </p>
            </div>
            <p className='footer-descc'>{type}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentCard;

import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Accordion, Dropdown } from "react-bootstrap";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Label,
} from "recharts";
import {
  Footer,
  StatBox,
  MainHeader,
  InvestorSlider,
  OpenSection,
  BlogCardMain,
  TestimonialCard,
  InvestorCard,
  CallToAction,
  ProfitCalculate,
  FillKYCAction,
} from "components";

import useNavigate from "hooks/Navigate";

import { MdOutlineAnnouncement } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import Link from "hooks/Link";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import apis from "services";
import Slider from "react-slick";
import { testimonials, investors, teamMembers } from "./../dummyData";
import TeamCard from "components/TeamCard";
import imageUrl from "utils/imageUrl";
import { RiDoubleQuotesL } from "react-icons/ri";
import cms from "services/cms";
import detectLang from "utils/detectLang";
import cmsImage from "utils/cmsImage";
import _html from "utils/_html";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import CustomHelmet from "../components/CustomHelmet";
import Question from "components/Question";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { BsFillCircleFill } from "react-icons/bs";
import { getMessaging, getToken } from "firebase/messaging";
import { firebaseApp, messaging } from ".././firebase";
import { setUser } from "redux/slices/userSlice";
import { setAdmin } from "redux/slices/adminSlice";
import homeSecThree from "../assets/images/home-sec-3.svg";
import homeSecFour from "../assets/images/home-sec-four.png";
import homeSecFive from "../assets/images/home-sec-five.png";
import homeSecSix from "../assets/images/home-sec-six.png";
import homeSecSevenLeft from "../assets/images/home-sec-seven-left.png";
import homeSecSevenRight from "../assets/images/home-sec-seven-right.png";
import homeSecSevenvideo from "../assets/images/home-sec-seven-video.png";

const settings = {
  autoplay: true,
  arrows: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const settings_blog = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const settings_new_blog = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,

  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const settings_3 = {
  autoplay: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  swipeToSlide: true,
  slidesToScroll: 1,
  nextArrow: <HiOutlineArrowNarrowRight />,
  prevArrow: <HiOutlineArrowNarrowLeft />,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const settings_2 = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const LandingPage = () => {
  const { user } = useSelector((store) => store.user);
  const { adminUser } = useSelector((store) => store.admin);
  const { t } = useTranslation();
  const [type, setType] = useState(false);
  const slider = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const searchParams = new URLSearchParams(useLocation().search);
  let { id } = Object.fromEntries(searchParams);
  const navigate = useNavigate();

  const dataOne = [
    {
      year: "2016",
      Iphone: 0,
    },
    {
      year: "2017",
      Iphone: 3000,
    },
    {
      year: "2018",
      Iphone: 2000,
    },
    {
      year: "2019",
      Iphone: 2780,
    },
    {
      year: "2020",
      Iphone: 1890,
    },
    {
      year: "2021",
      Iphone: 2390,
    },
    {
      year: "2022",
      Iphone: 0,
    },
  ];
  const dataTwo = [
    {
      year: "03:30",
      Iphone: 0,
    },
    {
      year: "03:30",
      Iphone: -0.5,
    },
    {
      year: "08:00",
      Iphone: 1,
    },
    {
      year: "12:50",
      Iphone: -1.5,
    },
    {
      year: "17:30",
      Iphone: -2,
    },
    {
      year: "22:20",
      Iphone: -2.5,
    },
    {
      year: "22:20",
      Iphone: -3,
    },
    {
      year: "22:20",
      Iphone: -3.5,
    },
  ];

  const lang = localStorage.getItem("i18nextLng") || "sp";

  const [setting, setSetting] = useState({});
  const [section, setSection] = useState([]);

  const [active, setActive] = useState(false);

  const [active1, setActive1] = useState(false);

  const [categories, setCategories] = useState([]);

  const [seos, setSeos] = useState({});

  const toggleActive = () => {
    setActive(!active);
  };

  const toggleActive1 = () => {
    setActive1(!active1);
  };
  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  let [newsFeet, setNewsFeet] = useState({ email: "" });

  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    setNewsFeet((prevState) => ({ ...prevState, [id]: value }));
  };

  const [contactus, setContactUs] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const onChangeContactUsHandler = (e) => {
    const { id, value } = e.target;
    setContactUs((prevState) => ({ ...prevState, [id]: value }));
  };

  let [blogs, setBlogs] = useState([]);

  const fetchData = async () => {
    const res = await apis.getTotalRecord();
    setData(res?.data);
  };

  const fetchBlogs = async () => {
    const res = await apis.getAllBlogs();
    setBlogs(res?.data?.blog);
  };
  const fetchSetting = async () => {
    const res = await cms.getSetting(
      "header,service_setting,join_setting,team,investor,ceo,notice,thinking,footer,work,banner,footer",
      "all,home"
    );
    setSetting(res?.data?.data);
  };
  const fetchSection = async () => {
    const res = await cms.getSection(
      "work,join_section,team,investor,social",
      "home,all"
    );
    setSection(res?.data?.data);
  };
  const fetchSeo = async () => {
    const res = await cms.getSeo("home");
    setSeos(res?.data?.data);
  };

  const fetchCategory = async () => {
    const res = await cms.getCategory();
    setCategories(res?.data?.data);
  };
  const submit = async (e) => {
    e.preventDefault();
    const res = await apis.addNewsFeet(newsFeet);
    if (res?.data?.status) {
      setNewsFeet({ email: "" });
      toast.success(res.data?.message);
    } else {
      toast.error(res.data?.message);
      setNewsFeet({ email: "" });
    }
  };

  const fetchToken = async () => {
    return getToken(messaging, {
      vapidKey:
        "BMpVypoBuKIlz3lr5Mal3KlJY2ozYfIGPBS1Hy0wwLYCh4a-XaVx0uRhKj0-a7YBtSO--qZyAQgiLWg62avaM1k",
    })
      .then(async (currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          localStorage.setItem("not_token", currentToken);

          if (user) {
            const res = await apis.updateUserProfileToken(user?.id, {
              not_token: currentToken,
            });
            if (res?.data?.status) {
              dispatch(
                setUser({ id: res?.data.user?._id, ...res?.data?.user })
              );
            }
          } else if (adminUser) {
            const res = await apis.updateAdminProfileToken(adminUser?._id, {
              not_token: currentToken,
            });
            if (res?.data?.status) {
              dispatch(setAdmin(res?.data?.admin));
            }
          }
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };
  useEffect(() => {
    (async () => {
      if (user || adminUser) {
        await fetchToken();
      }

      await fetchSetting();
      await fetchSection();
      await fetchSeo();
      await fetchCategory();
      await fetchData();
      await fetchBlogs();
    })();
  }, []);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaying1, setIsPlaying1] = useState(false);
  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  const togglePlay = () => {
    if (videoRef.current.paused || videoRef.current.ended) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  const togglePlay1 = () => {
    if (videoRef1.current.paused || videoRef1.current.ended) {
      videoRef1.current.play();
      setIsPlaying1(true);
    } else {
      videoRef1.current.pause();
      setIsPlaying1(false);
    }
  };
  const togglePlay2 = () => {
    if (videoRef2.current.paused || videoRef2.current.ended) {
      videoRef2.current.play();
      setIsPlaying(true);
    } else {
      videoRef2.current.pause();
      setIsPlaying(false);
    }
  };
  const handleVideoEnded = () => {
    setIsPlaying(false);
  };
  const handleVideoEnded1 = () => {
    setIsPlaying1(false);
  };

  useEffect(() => {
    document
      .getElementById(id)
      ?.scrollIntoView?.({ block: "start", behavior: "smooth" });
  }, [id]);

  const submitContactUs = async (e) => {
    e.preventDefault();
    setType(false);

    const res = await apis.contactUs(contactus);
    if (res?.status) {
      toast.success(res?.data?.message);
    }
  };

  return (
    <>
      <div
        className="landing-main"
        onClick={() => {
          if (showDropdown) setShowDropdown(!showDropdown);
        }}
      >
        {/* <div className="form-form">F<br/>O<br/>R<br/>M</div> */}

        <div className={`landing-page landing-main-page custom-scroll`}>
          <div className="scroll-icon-wrapper" onClick={handleScroll}>
            <i
              className="fa-solid fa-arrow-up"
              style={{ fontSize: "35px" }}
            ></i>
          </div>

          <div className="main-section-wrapper position-relative">
            <section className="main-section">
              <MainHeader
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
              />
              {seos && seos?.seos ? (
                <CustomHelmet seo={seos?.seos} lang={lang} />
              ) : (
                ""
              )}
            </section>
            <div className="home-sec-1">
              <div className="bg-overlay"></div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="home-sec-1-left">
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: t("home_sec_1.title"),
                        }}
                      ></h1>
                      <p>{t("home_sec_1.desc")}</p>
                      <div className="button">
                        <button onClick={() => navigate("/invest")}>
                          {t("home_sec_1.button_1")}
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9807 15.1979 10.9807 14.8021 11.2247 14.5581L15.1578 10.625H3.33334C2.98817 10.625 2.70834 10.3452 2.70834 10C2.70834 9.65482 2.98817 9.375 3.33334 9.375H15.1578L11.2247 5.44194C10.9807 5.19786 10.9807 4.80214 11.2247 4.55806Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                        {/* <button onClick={() => navigate("/contact-us")}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.00623 2.83908C6.40629 1.43903 8.76891 1.54546 9.74375 3.29223L10.2846 4.26133C10.9212 5.40202 10.6499 6.84125 9.718 7.78456C9.70558 7.80157 9.6398 7.89734 9.63161 8.06469C9.62115 8.27829 9.69701 8.77227 10.4624 9.53763C11.2275 10.3027 11.7214 10.3788 11.9351 10.3684C12.1026 10.3602 12.1984 10.2944 12.2154 10.282C13.1588 9.35008 14.598 9.07881 15.7387 9.7154L16.7078 10.2562C18.4545 11.2311 18.561 13.5937 17.1609 14.9938C16.412 15.7427 15.4163 16.4081 14.2461 16.4524C12.512 16.5182 9.63263 16.0703 6.78114 13.2189C3.92966 10.3674 3.48183 7.48801 3.54757 5.7539C3.59193 4.58373 4.25735 3.58797 5.00623 2.83908ZM8.65223 3.90139C8.15305 3.00694 6.81164 2.80144 5.89011 3.72297C5.24399 4.36909 4.82393 5.08227 4.79668 5.80125C4.74185 7.24737 5.09889 9.76883 7.66503 12.335C10.2312 14.9011 12.7526 15.2581 14.1988 15.2033C14.9177 15.1761 15.6309 14.756 16.277 14.1099C17.1986 13.1884 16.9931 11.8469 16.0986 11.3478L15.1295 10.8069C14.5267 10.4705 13.6799 10.5853 13.0855 11.1797L13.0853 11.1798C13.0269 11.2382 12.6554 11.5848 11.9958 11.6169C11.3206 11.6498 10.5034 11.3464 9.57849 10.4215C8.65331 9.49634 8.35004 8.67884 8.38311 8.00356C8.4154 7.34396 8.76225 6.97263 8.82029 6.91458L8.82032 6.91456C9.41475 6.32013 9.52951 5.47334 9.19307 4.8705L8.65223 3.90139Z"
                              fill="#252525"
                            />
                          </svg>
                          {t("home_sec_1.button_2")}
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="home-sec-1-right"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-sec-two">
              <div className="home-sec-twe-wrap">
                <InvestorSlider />
              </div>
            </div>
            <div className="home-sec-three">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6  my-auto">
                    <div className="home-sec-three-left">
                      <img src={homeSecThree} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="home-sec-three-right">
                      <h2>{t("home_sec_3.title")}</h2>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: t("home_sec_3.sub_title"),
                        }}
                      ></h3>
                      <p className="p1">{t("home_sec_3.desc_1")}</p>
                      <p className="p2">{t("home_sec_3.desc_2")}</p>
                      <div className="button">
                        <button onClick={() => navigate("/invest")}>
                          {t("home_sec_1.button_1")}
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9807 15.1979 10.9807 14.8021 11.2247 14.5581L15.1578 10.625H3.33334C2.98817 10.625 2.70834 10.3452 2.70834 10C2.70834 9.65482 2.98817 9.375 3.33334 9.375H15.1578L11.2247 5.44194C10.9807 5.19786 10.9807 4.80214 11.2247 4.55806Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-sec-four">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="home-sec-four-left">
                      <h2>{t("home_sec_4.title")}</h2>

                      <h3
                        dangerouslySetInnerHTML={{
                          __html: t("home_sec_4.sub_title"),
                        }}
                      ></h3>
                      <p className="p1">{t("home_sec_4.desc_1")}</p>
                      <p className="p2">{t("home_sec_4.desc_2")}</p>
                      <div className="button">
                        <button onClick={() => navigate("/invest")}>
                          {t("home_sec_4.button_1")}
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6  my-auto">
                    <div className="home-sec-four-right">
                      <img src={homeSecFour} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-sec-five">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2>{t("home_sec_5.title")}</h2>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: t("home_sec_5.sub_title"),
                      }}
                    ></h3>
                  </div>
                  <div className="col-lg-4">
                    <div className="left">
                      <div className="home-sec-five-left-one">
                        <div className="number">1</div>
                        <h4>{t("home_sec_5.title_1")}</h4>
                        <p>{t("home_sec_5.desc_1")}</p>
                      </div>
                      <div className="home-sec-five-left-two">
                        <div className="number">3</div>
                        <h4>{t("home_sec_5.title_3")}</h4>
                        <p>{t("home_sec_5.desc_3")}</p>
                      </div>
                      <div className="home-sec-five-left-three">
                        <div className="number">5</div>
                        <h4>{t("home_sec_5.title_5")}</h4>
                        <p>{t("home_sec_5.desc_5")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="home-sec-five-center">
                      <img src={homeSecFive} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="right">
                      <div className="home-sec-five-right-one">
                        <div className="number">2</div>
                        <h4>{t("home_sec_5.title_2")}</h4>
                        <p>{t("home_sec_5.desc_2")}</p>
                      </div>
                      <div className="home-sec-five-right-two">
                        <div className="number">4</div>
                        <h4>{t("home_sec_5.title_4")}</h4>
                        <p>{t("home_sec_5.desc_4")}</p>
                      </div>
                      <div className="home-sec-five-right-three">
                        <div className="number">6</div>
                        <h4>{t("home_sec_5.title_6")}</h4>
                        <p>{t("home_sec_5.desc_6")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-sec-six">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="home-sec-six-left">
                      <h2>{t("home_sec_6.title")}</h2>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: t("home_sec_6.sub_title"),
                        }}
                      ></h3>
                      <p className="p1">{t("home_sec_6.desc_1")}</p>
                      <p className="p2">{t("home_sec_6.desc_1")}</p>
                      <div className="button">
                        <button onClick={() => navigate("/invest")}>
                          {t("home_sec_6.button_1")}
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 my-auto">
                    <div className="home-sec-six-right">
                      <img src={homeSecSix} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-sec-seven">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2>{t("home_sec_7.title")}</h2>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: t("home_sec_7.sub_title"),
                      }}
                    ></h3>
                  </div>
                  <div className="col-lg-8 mx-auto">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="video">
                          <video
                            ref={videoRef1}
                            onEnded={handleVideoEnded1}
                            onClick={togglePlay1}
                            loop
                            playsinline={true}
                            width={"100%"}
                            height={"100%"}
                            style={{ opacity: isPlaying1 ? 1 : 0 }}
                          >
                            <source
                              src="/videos/testimonial.mp4"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                          <>
                            <div
                              className="background"
                              style={{ opacity: isPlaying1 ? 0 : 1 }}
                            >
                              <img
                                onClick={togglePlay1}
                                src={homeSecSevenvideo}
                                alt=""
                              />
                            </div>
                            <div className="left-img">
                              <img
                                src={homeSecSevenLeft}
                                alt=""
                                style={{ opacity: isPlaying1 ? "0" : "1" }}
                              />
                            </div>
                            <div className="right-img">
                              <img
                                src={homeSecSevenRight}
                                alt=""
                                style={{ opacity: isPlaying1 ? "0" : "1" }}
                              />
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-sec-eight sec-five">
              <div className="home-sec-eight-bg"></div>
              <ProfitCalculate />
            </div>

            <div className="home-sec-ten">
              <div className="container">
                <div className="col-12">
                  <h1
                    dangerouslySetInnerHTML={{ __html: t("home_sec_10.title") }}
                  ></h1>
                  <p>{t("home_sec_10.desc")}</p>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="left">
                          <div className="cardd">
                            <div className="right-con">
                              <img
                                src="/images/profile-icon.png"
                                alt="profile-icon"
                              />
                            </div>
                            <div className="right-con">
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: t("home_sec_10.title_1"),
                                }}
                              ></h3>
                              <p>{t("home_sec_10.desc_1")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 my-auto">
                        <div className="right">
                          <div className="cardd">
                            <div className="right-con">
                              <img
                                src="/images/profile-icon.png"
                                alt="profile-icon"
                              />
                            </div>
                            <div className="right-con">
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: t("home_sec_10.title_2"),
                                }}
                              ></h3>
                              <p>{t("home_sec_10.desc_2")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="button">
                      <button onClick={() => navigate("/invest")}>
                        Invest Now
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-sec-eleven">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 my-auto">
                    <div className="left">
                      <div className="video">
                        <video
                          ref={videoRef2}
                          onEnded={handleVideoEnded}
                          onClick={togglePlay2}
                          loop
                          playsinline={true}
                        >
                          <source
                            src="/videos/ANTONIOINTERVIEW.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        {!isPlaying && (
                          <div className="background">
                            <img
                              onClick={togglePlay2}
                              src="/images/playPauseVideo.svg"
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 my-auto">
                    <div className="right">
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: t("home_sec_11.title"),
                        }}
                      ></h1>
                      <p>{t("home_sec_11.desc_1")}</p>
                      <p>{t("home_sec_11.desc_2")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-sec-twelve">
              <div className="bg-video">
                <video
                  className="bg-video-landing"
                  autoPlay
                  loop
                  muted
                  poster="../../../public/images/Rectangle-172.png"
                >
                  <source src="/videos/PRESSINTERVIEW.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="bg-overlay"></div>
              </div>
              <div className="data" id="hide-on-mobile">
                <div className="content">
                  <h1
                    dangerouslySetInnerHTML={{ __html: t("home_sec_12.title") }}
                  ></h1>
                  <div className="button">
                    <button onClick={() => navigate("/invest")}>
                      {t("home_sec_12.button_1")}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="content-card">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-12 my-auto">
                        <div className="cardd">
                          <h3>{t("home_sec_12.title_one")}</h3>
                          <h1>{t("home_sec_12.sub_title_one")}</h1>
                          <p>{t("home_sec_12.desc_one")}</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-12  my-auto">
                        <div className="cardd">
                          <h3>{t("home_sec_12.title_two")}</h3>
                          <h1>{t("home_sec_12.sub_title_two")}</h1>
                          <p>{t("home_sec_12.desc_two")}</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-12  my-auto">
                        <div className="cardd">
                          <h3>{t("home_sec_12.title_three")}</h3>
                          <h1>{t("home_sec_12.sub_title_three")}</h1>
                          <p>{t("home_sec_12.desc_three")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="data-0twelve">
              <div className="content">
                <h1
                  dangerouslySetInnerHTML={{ __html: t("home_sec_12.title") }}
                ></h1>
                <div className="button">

                </div>
              </div>
              <div className="content-card">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4 col-md-12 col-12 my-auto">
                      <div className="cardd">
                        <h3>{t("home_sec_12.title_one")}</h3>
                        <h1>{t("home_sec_12.sub_title_one")}</h1>
                        <p>{t("home_sec_12.desc_one")}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-12  my-auto">
                      <div className="cardd">
                        <h3>{t("home_sec_12.title_two")}</h3>
                        <h1>{t("home_sec_12.sub_title_two")}</h1>
                        <p>{t("home_sec_12.desc_two")}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-12  my-auto">
                      <div className="cardd">
                        <h3>{t("home_sec_12.title_three")}</h3>
                        <h1>{t("home_sec_12.sub_title_three")}</h1>
                        <p>{t("home_sec_12.desc_three")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button">
                <button onClick={() => navigate("/invest")}>
                  {t("home_sec_12.button_1")}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.2247 4.55806C11.4688 4.31398 11.8645 4.31398 12.1086 4.55806L17.1086 9.55806C17.3527 9.80214 17.3527 10.1979 17.1086 10.4419L12.1086 15.4419C11.8645 15.686 11.4688 15.686 11.2247 15.4419C10.9806 15.1979 10.9806 14.8021 11.2247 14.5581L15.1578 10.625H3.33333C2.98815 10.625 2.70833 10.3452 2.70833 10C2.70833 9.65482 2.98815 9.375 3.33333 9.375H15.1578L11.2247 5.44194C10.9806 5.19786 10.9806 4.80214 11.2247 4.55806Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <section className="sec-four">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 mx-auto">
                  <div>
                    <h2>{t("section_4.title")}</h2>
                    <Question
                      title={t("section_4.question_1")}
                      para={t("section_4.question_1_ans")}
                    />
                    <Question
                      title={t("section_4.question_2")}
                      para={t("section_4.question_2_ans")}
                    />
                    <Question
                      title={t("section_4.question_3")}
                      para={t("section_4.question_3_ans")}
                    />
                    <Question
                      title={t("section_4.question_4")}
                      para={t("section_4.question_4_ans")}
                    />
                    <Question
                      title={t("section_4.question_5")}
                      para={t("section_4.question_5_ans")}
                    />
                    <Question
                      title={t("section_4.question_6")}
                      para={t("section_4.question_6_ans")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="sec-ten">
            <div className="container-fluid">
              <div className=".sec-ten-wrap">
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{ __html: t("section_10.title") }}
                ></h2>
                {blogs?.length >= 3 ? (
                  <>
                    <Slider {...settings_new_blog}>
                      {blogs?.reverse()?.map((blog) => {
                        return (
                          <>
                            <BlogCardMain
                              id={blog._id}
                              slug={blog.slug}
                              title={blog.title}
                              img={blog.image}
                              desc={blog.description}
                              author={blog.author}
                              time={blog.createdAt}
                              authorImg={blog.authorImage}
                            />
                          </>
                        );
                      })}
                    </Slider>
                  </>
                ) : (
                  <>
                    <Row>
                      {blogs?.reverse()?.map((blog) => {
                        return (
                          <>
                            <Col>
                              <BlogCardMain
                                id={blog._id}
                                slug={blog.slug}
                                title={blog.title}
                                img={blog.image}
                                desc={blog.description}
                                author={blog.author}
                                time={blog.createdAt}
                                authorImg={blog.authorImage}
                              />
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </>
                )}
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LandingPage;

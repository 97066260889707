import * as React from "react";
import { useEffect } from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from 'react-redux';
import reduxApis from "redux/apis";
import imageUrl from "utils/imageUrl";
import DashboardChart from "components/DashboardChart";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import { TOTAL_SHARES } from "../../assets/constants";
import _ from "lodash"
import useLocalizedNavigate from "hooks/Navigate";
import { MdKeyboardArrowDown } from 'react-icons/md'
import apis from "services";
import moment from "moment";
import { formatDate } from "utils/formatDate";
import { getMessaging, getToken } from "firebase/messaging";
import { firebaseApp, messaging } from "../../firebase";
import { setUser } from "redux/slices/userSlice";
import { setAdmin } from "redux/slices/adminSlice";

const mdTheme = createTheme();


export default function Dashboard() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(true);
    const [filter, setFilter] = React.useState('daily')

    const { adminUser } = useSelector((store) => store.admin);
    const [userProfit, setUserProfit] = React.useState([]);
    const [currentProfitDateData, setCurrentProfitDateData] = React.useState({});



    const [showfilter, setShowFilter] = React.useState(false)
    const [chartData, setChartData] = React.useState(
        {
            data:
                [0, 5, 10, 15, 5, 15, 20, 5, 20, 25, 5, 25, 30, 5, 30, 35, 5, 35, 45, 5, 45, 50, 5, 50, 55, 5, 55, 5, 55, 60]
            , categories:
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
        })
    const { t } = useTranslation();

    const navigate = useLocalizedNavigate();
    const toggleDrawer = () => setOpen(!open);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const { user } = useSelector((store) => store.user);
    const { wallet } = useSelector((store) => store.wallet);

    const getUserProfit = async () => {
        const response = await apis.userProfit(user?.id)
        if (response?.status) {
            setUserProfit(response?.data?.transaction)
            const lastTransaction = response?.data?.transaction?.[response?.data?.transaction?.length - 1];
            setCurrentProfitDateData(lastTransaction);
        }
    }
    const fetchToken = async () => {
        return getToken(messaging, { vapidKey: 'BMpVypoBuKIlz3lr5Mal3KlJY2ozYfIGPBS1Hy0wwLYCh4a-XaVx0uRhKj0-a7YBtSO--qZyAQgiLWg62avaM1k' }).then(async (currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                localStorage.setItem("not_token", currentToken)
                if (user) {
                    const res = await apis.updateUserProfileToken(user?.id, { not_token: currentToken })
                    if (res?.data?.status) {
                        dispatch(setUser({ id: res?.data.user?._id, ...res?.data?.user }));
                    }
                }
                else if (adminUser) {
                    const res = await apis.updateAdminProfileToken(adminUser?._id, { not_token: currentToken })
                    if (res?.data?.status) {
                        dispatch(setAdmin(res?.data?.admin))
                    }
                }


            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
    }

    useEffect(() => {
        setData((prevState) => ({ ...prevState, stock: wallet?.amount || 0, total: 0, dividend: wallet?.dividend || 0 }))
    }, [wallet])

    console.log(userProfit, "userProfit")
    const [data, setData] = React.useState({ stock: wallet?.amount || 0, total: 0, dividend: wallet?.dividend || 0 })
    useEffect(() => {
        (async () => {
            if (user) {
                const res = await dispatch(reduxApis.getWallet(user.id))
                await getUserProfit();
            }
        })();
    }, []);


    useEffect(() => {
        (async () => {

            if (user || adminUser) {
                await fetchToken();
            }

        })();
    }, [])

    function convert(n) {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0 ?
            sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal :
            sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    }
    const dispatch = useDispatch();

    const popOpen = Boolean(anchorEl);
    const id = popOpen ? "simple-popover" : undefined;

    const updateChartData = (select) => {
        let data = [10, 5, 10, 15, 5, 15, 20, 5, 20, 25, 5, 25, 30, 5, 30, 35, 5, 35, 45, 5, 45, 50, 5, 50, 55, 5, 55, 5, 55, 60]
        let monthNAme = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let tempData = []
        let category = []
        if (select == 'daily' || select == 'Diaria') {


            setFilter(select)
            for (let i = 0; i < 30; i++) {
                tempData.push(data[i])
                category.push(i + 1)
                console.log(tempData, category);
                setChartData({ data: tempData, categories: category })
            }

            const myParticipant = convert(wallet?.amount / TOTAL_SHARES) || 0
            const investedAmount = wallet?.amount || 0
            let result = (investedAmount * myParticipant / 100)
            setData((prevState) => ({ ...prevState, total: result }))

        }
        else if (select == 'weekly' || select == 'semanalmente') {
            setFilter(select)
            for (let i = 0; i < 7; i++) {
                tempData.push(data[i])
                category.push(i + 1)
                console.log(tempData, category);
                setChartData({ data: tempData, categories: category })
            }

            let totalProfit = 0
            let myParticipant = convert(wallet?.amount / TOTAL_SHARES) || 0
            let investedAmount = wallet?.amount || 0
            let result = (investedAmount * myParticipant / 100)



            setData((prevState) => ({ ...prevState, total: totalProfit }))



        } else {
            setFilter(select)
            for (let i = 0; i < 12; i++) {
                tempData.push(data[i])
                category.push(monthNAme[i])
                console.log(tempData, category);
                setChartData({ data: tempData, categories: category })
            }


            const myParticipant = convert(wallet?.amount / TOTAL_SHARES) || 0
            const investedAmount = wallet?.amount || 0
            let result = (investedAmount * myParticipant / 100)

            console.log(result, "resultresult")


        }
    }

    function getCurrentOrNearestPastProfit(data) {
        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toISOString().slice(0, 10);

        // Find the profit data for the current or nearest past date
        const profitData = data.find((item) => {

            return item.createdAt >= formattedCurrentDate
        });

        if (profitData) {
            return parseFloat(profitData.totalProfitAmount).toFixed(10);
        } else {
            // If there's no data for the current or past date, display 0.00
            return "0.00000000";
        }
    }

    console.log(currentProfitDateData, "currentProfitDateData")
    return (
        <ThemeProvider theme={mdTheme}>
            <Box className="dashboard-main">
                <AdminNewSidebar />
                <div className="dashboard-section-main">
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                        }}
                        className="custom-scroll"
                    >
                        <Header />
                        <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
                            <div className="deshboard-header-wrapper">
                                <h3 className="dashboard-title">{t("titles.dashboard")}</h3>
                                {wallet?.shares > 0 && (
                                    <div className="pt-2">
                                        <Alert severity="info">You are {convert(wallet?.amount / TOTAL_SHARES)}%
                                            of Participate of Java Times fundraiser</Alert>
                                    </div>
                                )}
                                <div className="d-flex justify-content-center mt-2">
                                    <button className="primary-btn-profit mx-3" onClick={() => navigate('/invest')}>{t("buttons.invest")}</button>
                                    <button className="primary-btn-profit mx-3" onClick={() => navigate(`/?id=roi`)}>P-ROI</button>

                                </div>
                            </div>

                            <Grid classes="d-flex justify-content-center" container className="mt-4">
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Paper
                                        className="dashboard-box"
                                    >
                                        <div className="text">
                                            <h3 className="">{t("labels.dashboard_1")}</h3>
                                            <h4 className="">{wallet?.amount}</h4>

                                        </div>
                                        <img src={imageUrl('d_available_shares.png')} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Paper
                                        className="dashboard-box"
                                    >
                                        <div className="text">
                                            <h3 className="">{t("labels.dashboard_2")}</h3>
                                            <h4 className="">{wallet?.shares}</h4>
                                        </div>
                                        <img src={imageUrl('d_shares.png')} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Paper
                                        className="dashboard-box"
                                    >
                                        <div className="text">
                                            <h3 className="">{t("labels.dashboard_3")}</h3>
                                            <h4 className="">{wallet?.dividend.toFixed(4)}</h4>
                                        </div>
                                        <img src={imageUrl('d_profit.png')} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Paper
                                        className="dashboard-box"
                                    >
                                        <div className="text">
                                            <h3 className="">{t("labels.token")}</h3>
                                            <h4 className="">{wallet?.jtcToken || 0}</h4>
                                        </div>
                                        <img src={imageUrl('d_profit.png')} />
                                    </Paper>
                                </Grid>
                            </Grid>


                            {
                                userProfit?.length > 0 &&

                                <div className="filter-drop-down">
                                    <div className="dropdown">
                                        <div className="select" onClick={() => { setShowFilter(!showfilter) }}>
                                            <h6>{formatDate(currentProfitDateData?.createdAt)}</h6>
                                            {/* <h6>{(currentProfitDateData?.createdAt)}</h6> */}

                                            <MdKeyboardArrowDown />
                                        </div>
                                        <div className={`options ${showfilter ? 'active' : ''}`} onClick={() => { setShowFilter(!showfilter) }}>
                                            <lu>
                                                {userProfit?.map((item) => (
                                                    <div onClick={() => { setCurrentProfitDateData(item) }} style={{ display: 'flex', background: 'white' }}>
                                                        <li onClick={() => { updateChartData(t("labels.dashboard_daily")) }}>
                                                            {formatDate(item?.createdAt)}
                                                            {/* {(item?.createdAt)} */}
                                                        </li>
                                                    </div>

                                                ))}

                                                {/* <li onClick={() => { updateChartData(t("labels.dashboard_daily")) }}>
                                                {t("labels.dashboard_daily")}
                                            </li>
                                            <li onClick={() => { updateChartData(t("labels.dashboard_Weekly")) }}>
                                                {t("labels.dashboard_Weekly")}
                                            </li>
                                            <li onClick={() => { updateChartData(t("labels.dashboard_Monthly")) }}>
                                                {t("labels.dashboard_Monthly")}
                                             </li> */}
                                            </lu>
                                        </div>
                                    </div>
                                </div>

                            }

                            <div className="jtc-share mt-5">
                                <h2>JTC Profit shares</h2>
                            </div>
                            <Grid container className="mt-2">
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Paper
                                        className="dashboard-share-box"
                                    >
                                        <div className="text index1">
                                            <h3 className="">{t("labels.dashboard_Stock_value")}</h3>
                                            <h4 className="">{(wallet?.amount || 0.00).toFixed(4)}</h4>
                                        </div>
                                    </Paper>
                                </Grid>



                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Paper
                                        className="dashboard-share-box"
                                    >
                                        <div className="text index4">
                                            <h3 className="">{t("labels.currentProfitAmount")} </h3>
                                            <h4 className="">{parseFloat(currentProfitDateData?.profitAmount || 0.00).toFixed(12)}</h4>
                                        </div>
                                    </Paper>

                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Paper
                                        className="dashboard-share-box"
                                    >
                                        <div className="text index2">
                                            <h3 className="">{t("labels.totalProfitAmount")} </h3>
                                            <h4 className="">{parseFloat(currentProfitDateData?.totalProfitAmount || 0.00).toFixed(12)}</h4>
                                        </div>
                                    </Paper>
                                </Grid>


                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <Paper
                                        className="dashboard-share-box"
                                    >
                                        <div className="text index4">
                                            <h3 className="">{t("labels.loss")} </h3>
                                            <h4 className="">{parseFloat(currentProfitDateData?.loss || 0.00).toFixed(12)}</h4>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                            {/* <DashboardChart chartData={chartData} /> */}

                        </Container>
                    </Box>
                </div>
            </Box>
        </ThemeProvider>
    );
}


const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: "#E21C21",
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, Grid, Paper } from "@mui/material";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header, PageLoader } from "components";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Button } from "react-bootstrap";
import useNavigate from "hooks/Navigate";
import { SUB_ADMIN_TYPES } from "assets/constants";
import DocumentCard from "components/DocumentCard";
import { useRef } from "react";
import apis from "services";
import { FiUpload } from 'react-icons/fi';
import { CircularProgress } from "@mui/material";
import INEModal from "components/modals/INEModal";
import { setUserInvestment } from "redux/slices/userSlice";

const mdTheme = createTheme();

const Document = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false);
  const { adminUser } = useSelector((store) => store.admin);
  const { user_analytics, user } = useSelector((store) => store.user);
  const [document, setDocument] = useState({});

  console.log(user_analytics, "user_analytics")
  const walletData = _.get(adminUser, "wallet", { share: 0, balance: 0 });

  const reserveWalletData = _.get(adminUser, "reserveWallet", {
    share: 0,
    balance: 0,
  });

  const [front, setFront] = useState({});
  const [back, setBack] = useState({});


  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const getDocumentData = async () => {
    try {
      setLoading(true)
      const response = await apis.getUserDocuments(user?.id)
      if (response?.data?.status) {
        setData(response?.data?.documents)
        getFrontData(response?.data?.documents)
        getBackData(response?.data?.documents)
        dispatch(setUserInvestment(response?.data?.analytics))
        getData(response?.data?.documents);
      }

      setLoading(false)

    }
    catch (e) {

      setLoading(false)

      console.log(e?.message)
    }

  }

  const getFrontData = (data) => {
    data.map((item) => {
      if (item?.type === "INE_FRONT") {
        setFront(item);
      }
    });
  };
  const getData = (dataDocument) => {
    dataDocument.map((item) => {
      if (item?.type === "INE_FRONT" || item?.type === "INE_BACK") {
        setDocument(item);
      }
    });
  };


  const getBackData = (data) => {
    data.map((item) => {
      if (item?.type === 'INE_BACK') {
        setBack(item);
      }
    });
  };


  useEffect(() => {
    getDocumentData();
  }, [])

  console.log(data, "DATA")
  const newWalletData = _.get(adminUser, "newWallet", { share: 0, balance: 0 });
  const { t } = useTranslation();


  const toggleModal = () => {
    setModal((prevState) => !prevState);
  };

  // Function to check if a file is a PDF
  const isPDF = (url) => {
    return url.toLowerCase().endsWith('.pdf');
  };



  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box className="dashboard-main">
          <AdminNewSidebar />
          <div className="dashboard-section-main">
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
              className="custom-scroll"
            >
              <div>
                <Header />

                <div maxWidth="lg" sx={{ mt: 0, mb: 4 }} className="container-fluid">
                  <div className="doc-with-title">
                    <h3 className="doc-title">{t("titles.document")}</h3>
                  </div>
                  <div className="other-details">
                    <h4>{t("titles.document_sub_title")}</h4>
                    <p>{t("descs.document_descs")}</p>
                  </div>

                  {loading ? <><div className="d-flex justify-content-center align-items-center m-5"><CircularProgress color="error" /></div></> :
                    <>
                      <div className="row">
                        {/* <div className="col-lg-3">
                          <DocumentCard value={"profile"} data={data} type={"Profile photo or avatar"} />
                        </div> */}
                        <div className="col-lg-3">
                          <DocumentCard getDocumentData={getDocumentData} value={"birthCertificate"} data={data} type={"Birth certificate"} />
                        </div>
                        <div className="col-lg-3" onClick={toggleModal}>

                          <div className="doc-card" style={{ position: 'relative' }}>
                            <span className={`doc-card-status ${front?.status === "approved" && back?.status === "approved" ? "accepted" : front?.status === "rejected" || back?.status === "rejected" ? "rejected" : front?.status === "pending" || back?.status === "pending" ? "pending" : "processing"}`}>
                              {front?.status === "approved" && back?.status === "approved" ? "APPROVED" : front?.status === "rejected" || back?.status === "rejected" ? "Rejected" : front?.status === "pending" || back?.status === "pending" ? "Processing" : "Pending"}
                            </span>
                            <div className='doc-card-wrap'>
                              <div className="profile-nft-row1">
                                <div className="NFT-image-holder">
                                  <input
                                    type="file"
                                    style={{ display: "none" }}
                                  />
                                  {/* <img src='/images/doc-dummy-image.png' style={{ height: 250 }} alt="" /> */}
                                  {document?.document ? (
                                    isPDF(document.document) ? (
                                      // Display PDF
                                      <iframe src={document.document} width="100%" height="250" title="Document PDF" />

                                    ) : (
                                      // Display image
                                      <img src={document.document} style={{ height: 250 }} alt="Uploaded" />
                                    )
                                  ) : (
                                    <img src='/images/doc-dummy-image.png' style={{ height: 250 }} alt="" />
                                  )}
                                </div>
                                <div>
                                  <div className="NFT-image-upload-txt">
                                    <button className="button-styling browse-btn" >
                                      <FiUpload />
                                      {front?.status === "approved" && back?.status === "approved" ? "Completed" : "Upload"}
                                    </button>
                                    <p>
                                      Jpg & PDF 200kb
                                    </p>
                                  </div>
                                  <p className='footer-descc'>INE (Identification Document)</p>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="col-lg-3">
                          <DocumentCard getDocumentData={getDocumentData} value={"CURP"} data={data} type={"CURP (Unique Population Registry Code)"} />
                        </div>
                        <div className="col-lg-3">
                          <DocumentCard getDocumentData={getDocumentData} value={"RFC"} data={data} type={"RFC (Federal Taxpayer Registry)"} />
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-3">
                          <DocumentCard getDocumentData={getDocumentData} value={"address"} data={data} type={"Proof of address (maximum 2 months old)"} />
                        </div>

                        <div className="col-lg-3">
                          <DocumentCard getDocumentData={getDocumentData} value={"Licita"} data={data} type={"Letter of Proceeding Licita"} />
                        </div>
                        <div className="col-lg-3">
                          <DocumentCard getDocumentData={getDocumentData} value={"Fiscal"} data={data} type={"Fiscal Proof"} />
                        </div>
                        {
                          (user?.isKYCCompleted === "signatureVerified" || user?.isKYCCompleted === "completed" ) &&
                          <div className="col-lg-3">
                            <DocumentCard getDocumentData={getDocumentData} value={"contract"} data={data} type={"Contract Sign"} />
                          </div>
                        }
                        
                      </div>
                    </>
                  }
                </div>




              </div>
            </Box>
          </div>
        </Box>
      </ThemeProvider>
      <INEModal
        toggleModal={toggleModal}
        status={modal}
        userId={user?.id}
        getDocumentDataUser={getDocumentData}
      />
    </>
  );
};

export default Document;

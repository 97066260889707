import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import apis from "../../services";
import { DataGrid } from "@mui/x-data-grid";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import AdminUsersTable from "components/datagrid/AdminUsersTable";
import UsersDocumentTable from "components/datagrid/UsersDocumentTable";
import useNavigate from "hooks/Navigate";
import { Table, Form, Row, Col } from "react-bootstrap";

export const UserDocuments = () => {
  const [users, setUsers] = useState([]);
const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  /*Methods*/
  const fetchAllUsers = async () => {
    const res = await apis.getAllUser();
    if (res.data.status) setUsers(res.data.users);
  };



  useEffect(() => {
    (async () => {
      await fetchAllUsers();
    })();
  }, []);



  let tables;
  if (searchQuery) {
    tables = UsersDocumentTable(filteredUsers, {
      navigate,
    });
  }
  else {
    tables = UsersDocumentTable(users, {
      navigate,
    });
  }


  const filterUsers = () => {
    const filtered = users?.filter((user) => {
      const fullName = `${user.name} ${user.email}`.toLowerCase();
      return fullName?.includes(searchQuery.toLowerCase()) ;
    });
    setFilteredUsers(filtered);
  };
  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="xl" sx={{ mt: 0, mb: 4 }}>
              <div className="flex-with-title">
                <h3 className="dashboard-title">{t("titles.document")}</h3>
                {/* <Link to={"/admin/users/add"} className="lp-btn">
                  {t("buttons.add_user")}
                </Link> */}
              </div>
              <Col xs={12} md={6} lg={4} className="mt-3 p-0">
                <Form.Group className="my-2">
                  <Form.Label>Search by Name or Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name or email"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyUp={filterUsers}
                  />
                </Form.Group>
              </Col>
              <Grid
                item
                container
                justifyContent="flex-end"
                className="mt-4"
                sx={{ height: "600px" }}
              >
                <DataGrid
                  //checkboxSelection
                  rows={tables?.rows}
                  columns={tables?.columns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              </Grid>
            </Container>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default UserDocuments;

import React from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import {
  Delete,
  RemoveModerator,
  Send,
  VerifiedUser,
  Visibility,
  MonetizationOn
} from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
/**
 * @param users
 * @param methods
 * @return {{columns: [{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},{headerName: string, field: string, width: number},null], rows: *[]}}
 */
const UsersDocumentTable = (users = [], methods = {}) => {
  const { navigate, approveHandler, deleteHandler, openModal ,resetEmailHandler} = methods;
  const { language, setLanguage, t } = useTranslation();

  const columns = [
    { field: "id",
     headerName: "#",    
     width: 80 },
    {
      field: "fullName",
      headerName: t("fields.fullName"),
      minWidth: 150,
      flex: 1,
    },

    { field: "email", headerName: t("fields.email"), minWidth: 150, flex: 1 },
    { field: "profile", headerName: t("fields.profileCompleted"), minWidth: 100, flex: 1,
    renderCell: (props) => {
      const isCompleted = props.row.profile;
      return <span style={{color:'green'}}>{isCompleted}% </span>  }
  },

    {
      field: "verified",
      headerName: t("fields.verified"),
      width: 150,
      renderCell: (props) => {
        const isVerified = props.row.verified;
        return isVerified ?<span style={{color:'green'}}>Verified </span>: <span style={{color:"red"}}>Not Verified</span>
      }
    },
    {
      field: "isKYCCompleted",
      headerName: t("fields.profileStatus"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("fields.actions"),
      minWidth: 100,
      flex: 1,
      renderCell: (props) => {
        const isVerified = props.row.verified;
        const id = props.row._id;
        return (
          <Grid container>
          <Grid item>
              <Tooltip title="View" arrow>
                <IconButton
                  size="small"
                  onClick={() => navigate(`/admin/users-document/${id}`)}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const rows =
    users.length === 0
      ? []
      : [
          ...users.map((user, id) => ({
            id: id + 1,
            fullName: user.name,
            email: user.email,
            profile: user.profileCompleted,
            verified: user.isVerified,
            isKYCCompleted: user.isKYCCompleted,
            _id: user._id,
          })),
        ];

  return { rows, columns };
};

export default UsersDocumentTable;

import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillInstagram,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { useParams } from "react-router-dom";
import cms from "services/cms";
import cmsImage from "utils/cmsImage";
import detectLang from "utils/detectLang";
import "../assets/css/footer.css";
import { toast } from "react-toastify";
import apis from "services";
import sp from '../assets/images/sp.png'

const Footer = () => {
  let [newsFeet, setNewsFeet] = useState({ email: "" });

  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    setNewsFeet((prevState) => ({ ...prevState, [id]: value }));
  };

  const submit = async (e) => {
    e.preventDefault();
    const res = await apis.addNewsFeet(newsFeet);
    if (res?.data?.status) {
      setNewsFeet({ email: "" });
      toast.success(res.data?.message);
    } else {
      toast.error(res.data?.message);
      setNewsFeet({ email: "" });
    }
  };

  const { lang: _lang } = useParams();
  const lang = _lang || "sp";
  const [setting, setSetting] = useState({});
  const [socials, setSocials] = useState([]);
  const fetchSetting = async () => {
    const res = await cms.getSetting("footer", "all");
    setSetting(res?.data?.data?.footer);
  };
  const fetchSection = async () => {
    const res = await cms.getSection("social", "all");
    setSocials(res?.data?.data?.social);
  };
  useEffect(() => {
    (async () => {
      await fetchSetting();
      await fetchSection();
    })();
  }, []);
  return (
    <>
      <div className="custom-footer pt-5">
        <Container className="pb-5">
          <Row>
            <Col md={3}>
              <div className="footer-fr pb-3">
                <p className="h5 footer-heading">CONTACT US</p>
                <p className="footer-desc ">
                  {detectLang(setting, "contact_desc", lang)}
                </p>
                <ul>
                  <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span>{setting?.address}</span>
                  </li>
                  <li>
                    <i className="fa fa-phone" aria-hidden="true"></i>

                    <span>{setting?.phone}</span>
                  </li>
                  <li>
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <span>{setting?.email}</span>
                  </li>
                </ul>
                <img src={cmsImage(setting?.logo)} alt="" className="pt-1" />
              </div>
            </Col>
            <Col md={4}>
              <div className="footer-fr">
                <div className="footer-desc pb-3">
                  <p className="h5 footer-heading">OUR MISSION</p>
                  <p className="footer-desc">
                    {detectLang(setting, "mission_desc", lang)}
                  </p>
                  <p className="h5 footer-heading">OUR VISION</p>
                  <p className="footer-desc">
                    {detectLang(setting, "vission_desc", lang)}
                  </p>
                </div>
                {/* <div className="footer-m-desc">
                  <p className="h5  footer-heading">Footer Title Two</p>
                  <p className="">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy{" "}
                  </p>
                </div> */}
              </div>
            </Col>
            <Col md={5}>
              <div className="footer-fr">
                <p className="h5  footer-heading">NEWSLETTER</p>
                <p className="footer-desc ">
                  {detectLang(setting, "newsletter_desc", lang)}
                </p>
                <form onSubmit={submit}>
                  <div className="rounded-input-group my-4">
                    <input
                      type="email"
                      placeholder="your@gmail.com"
                      className="rounded-input"
                      required
                      id="email"
                      value={newsFeet.email}
                      onChange={onChangeHandler}
                    />
                    <button className="lp-btn" type="submit">
                      Get Started
                    </button>
                  </div>
                </form>
                <Row>
                  <Col sm={6}>
                    <p className="h5 footer-heading">POWERED BY</p>
                    <p className="footer-desc">
                      {detectLang(setting, "powered_desc", lang)}
                    </p>
                    <div className="footer-img pb-3">
                      <img src={cmsImage(setting?.powered_image)} alt="" />
                    
                    </div>
                      <img src={sp} alt="" />

                  </Col>
                  <Col sm={6}>
                    <p className="h5  footer-heading pb-2">FOLLOW US</p>
                    <div className="footer-icons">
                      {socials?.map((item, _ind) => {
                        return (
                          <>
                            <a
                              className="me-3"
                              href={item?.link}
                              target="_blank"
                            >
                              <img src={cmsImage(item?.icon)} />
                            </a>
                          </>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;

// import React, { useState } from "react";
// import { Fade, Flash } from "react-reveal/";
// import { Container } from "react-bootstrap";
// import useTranslation from "hooks/useTranslation";

// function Footer() {
//   const [email, setEmail] = useState("");
//   const { language, setLanguage, t } = useTranslation();

//   const onSubmitHandler = (e) => {
//     e.preventDefault();
//     setEmail("");
//   };

//   return (
//     <>
//         <div className="footer-wrapper">
//         <Container>
//           <footer className="red-bg">
//             <h3 className="text-white text-uppercase">
//               {t("titles.footer")}
//             </h3>
//             <div className="join-section">
//               <form onSubmit={onSubmitHandler}>
//                 <input
//                   id="custom"
//                   className="custom-input"
//                   placeholder={t("placeholders.email")}
//                   type="email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   required
//                 />
//                 <button className="btn secondary-btn">
//                   <i class="fa-solid fa-arrow-right"></i>
//                 </button>
//               </form>
//             </div>
//             <div className="footer-social">
//               <h3 className="common-heading text-white fw-light">{t("labels.follow_us")}</h3>
//               <ul>
//                 <li>
//                   <a href="#" target="_blank">
//                     <i className="fa-brands fa-facebook-f"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" target="_blank">
//                     <i className="fa-brands fa-twitter"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" target="_blank">
//                     <i className="fa-brands fa-instagram"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" target="_blank">
//                     <i className="fa-brands fa-linkedin-in"></i>
//                   </a>
//                 </li>
//               </ul>
//             </div>
//             <div className="last-section">
//               <h2 className="fw-light">{t("labels.main")}</h2>
//               <p>©2022 JAVA TIMES CAFÉ. {t("labels.rights")} </p>
//             </div>
//           </footer>
//         </Container>
//         </div>
//     </>
//   );
// }

// export default Footer;

import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useNavigate from "hooks/Navigate";
const GuaranteeModal = ({ status, toggleModal }) => {
  const navigate = useNavigate();
  const {language} = useSelector(store => store.language)
  const {  t } = useTranslation();
  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Java Times Caffe Guarantee</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{fontSize:'12px'}}>
          <Col style={{fontSize:'14px'}}>Dear Investor:</Col>
          <br/>        
          <Col>We are thrilled to have you as an investor in our project at Java Times Caffe. As a gesture of our commitment to your investment, we are pleased to provide you with the following guarantee:</Col>
          <br/>   
          <Col>Guaranteed Annual Returns: We guarantee that you will receive a minimum annual return of 10% or more on your investment in Java Times Caffe. This guarantee is based on our projections and historical performance, and we are confident in our ability to achieve this level of returns.</Col>
          <br/>   
          <Col>Money-Back Guarantee: If, for any reason, we fail to meet the guaranteed annual return of 10% or more, we will repurchase back all the shares amount you may have at the time. We understand the importance of your investment and want to ensure that you have peace of mind.</Col>
          <br/>   
          <Col>Please note that this guarantee is subject to the terms and conditions outlined in our investment agreement, and certain limitations may apply. We encourage you to carefully review the investment agreement and consult with your financial advisor before making any investment decisions.</Col>
          <br/>   
          <Col>At Java Times Caffe, we are committed to delivering value to our investors and creating a successful venture. We have a proven track record of performance and strive to exceed your expectations. We look forward to a mutually beneficial relationship and appreciate your trust in us.</Col>
          <br/>   
          <Col>Thank you for choosing to invest in Java Times Caffe. If you have any questions or require further information, please do not hesitate to contact us.</Col>
          <br/>
          <Col>Sincerely,</Col>
          <Col>Antonio Leite</Col>
          <Col>Founder</Col>
          <Col>Java Times Caffe</Col>
    
      </Modal.Body>
    </Modal>
  );
};

export default GuaranteeModal;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, Grid, Paper } from "@mui/material";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Button } from "react-bootstrap";
import useNavigate from "hooks/Navigate";
import { SUB_ADMIN_TYPES } from "assets/constants";
import SignatureSuccessModal from "components/modals/SignatureSuccessModal";


const mdTheme = createTheme();

const Wallet = () => {
  const navigate = useNavigate();

  const [tab, setTab] = useState("normal");
  const { adminUser } = useSelector((store) => store.admin);
  const { wallet } = useSelector((store) => store.wallet);

  const { user } = useSelector((store) => store.user);
  const walletData = _.get(adminUser, "wallet", { share: 0, balance: 0 });

  const [modal, setModal] = useState(false);
  const reserveWalletData = _.get(adminUser, "reserveWallet", {
    share: 0,
    balance: 0,
  });

  const newWalletData = _.get(adminUser, "newWallet", { share: 0, balance: 0 });
  const { t } = useTranslation();
  const toggleModal = () => {
    setModal((prevState) => !prevState);
  };
  return (
    <ThemeProvider theme={mdTheme}>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <div>
              <Header />
              <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
                <div className="flex-with-title">
                  <h3 className="dashboard-title">{t("titles.wallet")}</h3>
                  {/* {adminUser?.type === "owner" ? (
                    <button
                      className="primary-btn-profit mx-3"
                      onClick={() => navigate("/admin/withdrawl-request")}
                    >
                      Withdrawl
                    </button>
                  ) : user?.type === "investor" || "normal" ? (
                    <button
                      className="primary-btn-profit mx-3"
                      onClick={() => navigate("/user/withdrawl")}
                    >
                      Withdrawl
                    </button>
                  ) : (
                    <button
                      className="primary-btn-profit mx-3"
                      onClick={() => navigate("/sub-admin/withDrawl")}
                    >
                      Withdrawl
                    </button>
                  )} */}

                  {
                    user?.isKYCCompleted === "completed" && user?.isInvestor &&
                    <button className="primary-btn-profit mx-3 mt-2" onClick={() => toggleModal()}>KYC Contract Download</button>
                  }

                  {/* <button className="primary-btn-profit mx-3 mt-2" onClick={() => navigate('/invest')}>{t("buttons.invest")}</button> */}


                </div>
                <div className="mt-2">
                  Wallet Id :{" "}
                  <span>
                    {adminUser ? adminUser?.wallet?._id : wallet?._id}
                  </span>
                  {/* {adminUser ? (
                   <></>
                   ) : (
                    <>
                      {" "}
                      <button
                        className="btn btn-danger mx-5"
                        onClick={() => navigate("/user/withdrawl")}
                      >
                        Withdrawl
                      </button>
                    </>
                  )} */}
                </div>


                {adminUser?.type === "owner" ? (
                  <>
                    <div className="mt-2">
                      Reserve Wallet Id :{" "}
                      <span>{adminUser?.reserveWallet?._id}</span>
                    </div>
                    <div className="mt-2">
                      New Wallet Id : <span>{adminUser?.newWallet?._id}</span>
                    </div>
                    <div className="share-type d-flex justify-content-center">
                      <Button
                        className={
                          tab === "normal" ? "w-10 active_type " : "w-10"
                        }
                        variant="outline-danger"
                        onClick={() => setTab("normal")}
                      >
                        Normal Wallet
                      </Button>
                      <Button
                        className={
                          tab === "reserve" ? "w-10 active_type " : "w-10"
                        }
                        variant="outline-danger"
                        onClick={() => setTab("reserve")}
                      >
                        Reserve Wallet
                      </Button>
                      <Button
                        className={tab === "new" ? "w-10 active_type " : "w-10"}
                        variant="outline-danger"
                        onClick={() => setTab("new")}
                      >
                        New Wallet
                      </Button>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <Grid container classes="d-flex justify-content-center" className="mt-4">
                  {/* small boxes */}
                  {adminUser ? (
                    <>
                      {SUB_ADMIN_TYPES.includes(adminUser?.type) && <>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Paper
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                            }}
                            className="dashboard-box"
                          >
                            <h3 className="text-center">
                              {t("labels.wallet_2")}
                            </h3>
                            <h4>
                              {walletData?.shares || 0}{" "}
                            </h4>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Paper
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                            }}
                            className="dashboard-box"
                          >
                            <h3 className="text-center">{t("labels.balance")}</h3>
                            <h4>
                              {walletData?.balance || 0}{" "}
                            </h4>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Paper
                            sx={{
                              p: 2,
                              display: "flex",
                              flexDirection: "column",
                            }}
                            className="dashboard-box"
                          >
                            <h3 className="text-center">{t("labels.admin_dashboard_5")}</h3>
                            <h4>
                              {walletData?.dividend || 0}{" "}
                            </h4>
                          </Paper>
                        </Grid>
                        

                      </>}

                      {adminUser?.type === "owner" ? (
                        <>
                          {tab === "normal" && (
                            <>
                              <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="dashboard-box"
                                >
                                  <h3 className="text-center">
                                    {t("labels.wallet_2")}
                                  </h3>
                                  <h4>
                                    {adminUser
                                      ? walletData?.shares || 0
                                      : wallet?.shares || 0}{" "}
                                  </h4>
                                </Paper>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="dashboard-box"
                                >
                                  <h3 className="text-center">
                                    {t("labels.balance")}
                                  </h3>
                                  <h4>
                                    {adminUser
                                      ? walletData?.balance || 0
                                      : wallet?.shares || 0}{" "}
                                  </h4>
                                </Paper>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="dashboard-box"
                                >
                                  <h3 className="text-center">
                                    {t("labels.admin_dashboard_5")}
                                  </h3>
                                  <h4>
                                    {adminUser
                                      ? walletData?.dividend || 0
                                      : wallet?.shares || 0}{" "}
                                  </h4>
                                </Paper>
                              </Grid>
                            </>
                          )}
                          {tab === "reserve" && (
                            <>
                              <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="dashboard-box"
                                >
                                  <h3 className="text-center">
                                    {t("labels.reserveWalletShares")}
                                  </h3>
                                  <h4>{reserveWalletData?.shares}</h4>
                                </Paper>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="dashboard-box"
                                >
                                  <h3 className="text-center">
                                    {t("labels.reserveWalletBalance")}
                                  </h3>
                                  <h4>{reserveWalletData?.balance}</h4>
                                </Paper>
                              </Grid>
                              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="dashboard-box"
                                >
                                  <h3 className="text-center">
                                    {t("labels.admin_dashboard_5")}
                                  </h3>
                                  <h4>{reserveWalletData?.dividend || 0} </h4>
                                </Paper>
                              </Grid> */}
                            </>
                          )}

                          {tab === "new" && (
                            <>
                              <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="dashboard-box"
                                >
                                  <h3 className="text-center">
                                    {t("labels.newWalletShares")}
                                  </h3>
                                  <h4>{newWalletData?.shares}</h4>
                                </Paper>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="dashboard-box"
                                >
                                  <h3 className="text-center">
                                    {t("labels.newWalletBalance")}
                                  </h3>
                                  <h4>{newWalletData?.balance}</h4>
                                </Paper>
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Paper
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                  className="dashboard-box"
                                >
                                  <h3 className="text-center">
                                    {t("labels.admin_dashboard_5")}
                                  </h3>
                                  <h4>{newWalletData?.dividend || 0} </h4>
                                </Paper>
                              </Grid> */}

                              
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3} >
                        <Paper
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                          className="dashboard-box"
                        >
                          <h3 className="text-center">
                            {t("labels.wallet_1")}
                          </h3>
                          <h4>{wallet?.amount || 0}</h4>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                          className="dashboard-box"
                        >
                          <h3 className="text-center">
                            {t("labels.wallet_2")}
                          </h3>
                          <h4> {wallet?.shares || walletData?.shares || 0}</h4>
                        </Paper>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                          className="dashboard-box"
                        >
                          <h3 className="text-center">
                            {t("labels.dashboard_3")}
                          </h3>
                          <h4>{wallet?.dividend || 0}</h4>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Paper
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                          className="dashboard-box"
                        >
                          <h3 className="text-center">
                            {t("labels.token")}
                          </h3>
                          <h4>{wallet?.jtcToken || 0}</h4>
                        </Paper>
                      </Grid>
                    </>
                  )}
                </Grid>
                <SignatureSuccessModal
                  toggleModalSuccess={toggleModal}
                  status={modal}
                  userId={user?.id}
                />
              </Container>
            </div>
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default Wallet;

import React, { useState } from "react";
import { FaPhone } from "react-icons/fa";
import Slider from "@mui/material/Slider";
import GuaranteeModal from "components/modals/Guarantee";
import Link from 'hooks/Link';
import { useTranslation } from "react-i18next";
import useLocalizedNavigate from "hooks/Navigate";

const ProfitCalculate = () => {
  const { t } = useTranslation();
  const steps = [
    { value: 5000 },
    { value: 10000 },
    { value: 20000 },
    { value: 30000 },
    { value: 40000 },
    { value: 50000 },
    { value: 60000 },
    { value: 70000 },
    { value: 80000 },
    { value: 100000 },
    { value: 150000 },
    { value: 200000 },
    { value: 250000 },
    { value: 300000 },
    { value: 350000 },
    { value: 400000 },
    { value: 450000 },
    { value: 500000 },
    { value: 600000 },
    { value: 700000 },
    { value: 800000 },
    { value: 900000 },
    { value: 1000000 },
  ];
  const navigate = useLocalizedNavigate();
  const [status, setStatus] = useState(false);
  const [investment, setInvestment] = useState(1000);
  const [sharePrice, setSharePrice] = useState(20);
  const [year, setYear] = useState(1);

  const toggleModal = () => {
    setStatus((prevState) => !prevState);
  };
  const [result, setResult] = useState({
    profit: 600,
    exit: 4100,
    participant: 3500,
    investorShares: 175,
  });

  const YEAR_LIST = [1, 2, 3, 4, 5];

  const calculateProfit = () => {
    let profit = 0,
      participant = 0,
      exit = 0,
      investorShares = 0;
    if (year == 1) {

      profit = 0;
      participant = 0;
      exit = 0;
      investorShares = 0;

      setSharePrice(20);
      profit = (12 / 100) * investment;
      investorShares = ((70 / 100) * investment) / 20;
      participant = investorShares * 20;
      exit = profit + participant;

      setResult({ profit, participant, exit, investorShares });
    }
    if (year == 2) {
      profit = 0;
      participant = 0;
      exit = 0;
      investorShares = 0;

      setSharePrice(40);
      profit = (44 / 100) * investment;
      investorShares = ((70 / 100) * investment) / 20;
      participant = investorShares * 40;
      profit = (44 / 100) * investment;
      exit = profit + participant;

      setResult({ profit, participant, exit, investorShares });
    }
    if (year == 3) {

      profit = 0;
      participant = 0;
      exit = 0;
      investorShares = 0;

      setSharePrice(60);

      profit = (64 / 100) * investment;
      investorShares = ((70 / 100) * investment) / 20;
      participant = investorShares * 60;
      exit = profit + participant;

      setResult({ profit, participant, exit, investorShares });
    }
    if (year == 4) {
      profit = 0;
      participant = 0;
      exit = 0;
      investorShares = 0;

      setSharePrice(80);
      profit = (81 / 100) * investment;
      investorShares = ((70 / 100) * investment) / 20;
      participant = investorShares * 80;
      exit = profit + participant;

      setResult({ profit, participant, exit, investorShares });
    }
    if (year == 5) {
      profit = 0;
      participant = 0;
      exit = 0;
      investorShares = 0;
      setSharePrice(114.28);

      profit = (101 / 100) * investment;
      investorShares = ((70 / 100) * investment) / 20;
      participant = investorShares * 114.28;
      exit = profit + participant;

      setResult({ profit, participant, exit, investorShares });
    }
    document.getElementById('result_section')?.scrollIntoView?.({ block: 'start', behavior: 'smooth' })
  };
  const handleChange = (event, newValue) => {
    setSharePrice(newValue);
  };

  function valuetextInvestmentAmount(value) {
    setInvestment(value);
    return `$${value}`;
  }
  function valuetextSharePrice(value) {
    return `$${value}`;
  }

  return (
    <>

      <div className="container">
        <div className="row justify-content-center">
          {/* <div className="col-lg-4 col-sm-12 col-md-12 my-auto">
            <div className="layout-text-profit">
              <img src="images/logo.webp" alt="" />
              <h3 dangerouslySetInnerHTML={{ __html: t("section_5.title") }}></h3>
              <p>{t("section_5.desc")}</p>
              <div className="button" >
                <button onClick={()=>navigate("/contact-us")}>{t("section_5.button")} </button>
              </div>
            </div>
          </div> */}
          <div className="col-lg-6 col-sm-12 col-md-12 card-11">
            <div className="left">
              <div className="row">
              <h2>{t("home_sec_8.title")}</h2>
              </div>
              <div className="row">
                <div className="d-flex justify-content-between">
                  <h6 className="currency">{t("section_5.currency")}</h6>
                  <p className="layout_currency-text">MXN</p>
                </div>
              </div>
              <div className="row pt-4">
                <div className="d-flex justify-content-between">
                  <h6 className="money">{t("section_5.how_much_money")}</h6>

                </div>
                <div className="custome-slider-widget">
                  <Slider
                    style={{ color: "#A10707" }}
                    defaultValue={5000}
                    max={1000000}
                    min={5000}
                    getAriaValueText={valuetextInvestmentAmount}
                    valueLabelFormat={valuetextInvestmentAmount}
                    step={null}
                    marks={steps}
                  />
                  <div className="d-flex justify-content-between">
                    <h6 className="range">$5000</h6>
                    <h6 className="range">$1000000</h6>
                  </div>
                </div>
              </div>
              <div className="row">
                <span className="selected-range">${investment}</span>
              </div>
              <div className="row">
                <div className="d-flex justify-content-between">
                  <h6 className="years">{t("section_5.year")}:</h6>
                  <select
                    className="mt-1 select-option-profit"
                    id="year"
                    name="year"
                    required
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    {YEAR_LIST.map((item) => {
                      return (
                        <>
                          {" "}
                          <option value={item}>{item} {t("section_5.year")}</option>
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button onClick={toggleModal} className="cta">
                  {t("buttons.guarantee")}
                </button>

              </div>
            </div>
            <div className="row pt-5">
              <div className="button">
                <button onClick={calculateProfit} className="primary-btn-profit calculate">
                {t("section_5.calculate")}
                </button>
              </div>

              
              
            </div>

          </div>
          <div id="result_section" className="col-lg-6 col-sm-12 col-md-12  card-22">
            <div className="right">
              <div className="pt-1 result">
                <div className="">
                  <h3>{t("section_5.result")}</h3>
                </div>
                <div className="p-1 pt-3">
                  <div className="d-flex justify-content-between">
                    <div className="boder-bottom">
                      <h6>{t("section_5.year_of_investment")}</h6>
                      <div style={{ minWidth: '72px' }}>
                        <h5>{t("section_5.year")} 0{year}</h5>
                        <p className="line-text"></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row p-3">
                  <div className="d-flex justify-content-between">
                    <div className="boder-bottom">
                      <h6>{t("section_5.investment")}</h6>
                      <div style={{ minWidth: '72px' }}>
                        <h5>${investment}</h5>
                        <p className="line-text"></p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row p-3">
                  <div className="d-flex justify-content-between">
                    <div className="boder-bottom">
                      <h6>{t("section_5.share_price")}</h6>
                      <div style={{ minWidth: '72px' }}>
                        <h5>${sharePrice}</h5>
                        <p className="line-text"></p>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="row p-3">
                  <div className="d-flex justify-content-between">
                    <div className="boder-bottom">
                      <h6>{t("section_5.investor_shares")}</h6>
                      <div style={{ minWidth: '72px' }}>
                        <h5>{result.investorShares.toFixed(0)}</h5>
                        <p className="line-text"></p>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="row p-3">
                  <div className="d-flex justify-content-between">
                    <div className="boder-bottom">
                      <h6>{t("section_5.profit")}</h6>
                      <div style={{ minWidth: '72px' }}>
                        <h5>${result.profit.toFixed(0)}</h5>
                        <p className="line-text"></p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row p-3">
                  <div className="d-flex justify-content-between">
                    <div className="boder-bottom">
                      <h6>{t("section_5.participant")}</h6>
                      <div style={{ minWidth: '72px' }}>
                        <h5>${result.participant.toFixed(0)}</h5>
                        <p className="line-text"></p>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="row p-3">
                  <div className="d-flex justify-content-between">
                    <div className="boder-bottom">
                      <h6>{t("section_5.exit")}</h6>
                      <div style={{ minWidth: '72px' }}>
                        <h5>${result.exit.toFixed(0)}</h5>
                        <p className="line-text"></p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row pt-2 pb-3">
                <div className="d-flex justify-content-between">
                <h6>{t("section_5.no_hidden")}</h6>
                </div>
              </div> */}
              </div>
            </div>
          </div>

        </div>
      </div>

      <GuaranteeModal status={status} toggleModal={toggleModal} />
    </>
  );
};

export default ProfitCalculate;

// import { useEffect, useMemo, useState } from "react";
// import { Container } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
// import { STATIC_STORE_DATA, COUNTRY_LIST } from "../assets/constants";
// import apis from "../services";
// import { useSelector } from "react-redux";
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";
// import { Grid, Paper } from "@mui/material";

// import useNavigate from "hooks/Navigate";
// const CheckoutForm = () => {
//   const { wallet } = useSelector((store) => store.wallet);
//   const [seeMore, setSeeMore] = useState(false);
//   const toggleActive = () => {
//     setSeeMore(!seeMore);
//   };
//   // const [prices, setPrices] = useState({ user: 0, owner: 0, total: 0 });
//   const [loader, setLoader] = useState(false);
//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const { sharePerPrice } = STATIC_STORE_DATA;
//   const { language, setLanguage, t } = useTranslation();

//   const { user } = useSelector((store) => store.user);

//   const [tab, setTab] = useState("card");

//   useEffect(() => {
//     if (!state) {
//       navigate("/404");
//     }
//   }, []);

//   const products = state
//     ? [
//         state,
//         /*{name: 'Product Name', description: 'Brief description', price: 12},
//         {name: 'Product Name', description: 'Brief description', price: 12},
//         {name: 'Product Name', description: 'Brief description', price: 12},
//         {name: 'Product Name', description: 'Brief description', price: 12},*/
//       ]
//     : [];

//   /*useEffect(() => {
//         calculateValues(products)
//     }, []);

//     const calculateValues = (products) => {
//         let total = products.reduce((sum,{price}) => {
//             sum+=price;
//             return sum;
//         },0);

//         const user = total * 0.7;
//         const owner = total * 0.3;
//         total-= - owner;

//         setPrices({user,owner,total});
//     };*/

//   const totalPrice = useMemo(() => {
//     const storePrice = products.reduce((sum, store) => {
//       sum += store?.price || 0;
//       return sum;
//     }, 0);

//     return storePrice * state?.sharePerPrice;
//   }, []);

//   const submitHandlerWallet = async (e) => {
//     setLoader(true);
//     try {
//       const { data } = await apis.invest(state.id, {
//         userId: user.id,
//         shareAmount: state.price,
//         sharePerPrice: state.sharePerPrice,
//         totalShares: state.totalShares,
//         type: tab,
//       });
//       if (data.status) {
//         setLoader(false);

//         toast.success(data?.message);
//         navigate("/user/dashboard");
//       } else {
//         setLoader(false);
//         toast.error(data?.message);
//       }
//     } catch (e) {
//       setLoader(false);
//       toast.error(e.message);
//     }
//   };

//   const submitHandler = async (e) => {
//     e.preventDefault();
//     const form = new FormData(e.currentTarget);
//     const formData = Object.fromEntries(form);
//     setLoader(true);
//     try {
//       const { data } = await apis.invest(state.id, {
//         userId: user.id,
//         shareAmount: state.price,
//         sharePerPrice: state.sharePerPrice,
//         totalShares: state.totalShares,
//         type: tab,
//         ...formData,
//       });
//       if (data.status) {
//         toast.success(data?.message);
//         document.getElementById("billing-form").reset();
//         navigate("/user/dashboard");
//       } else {
//         toast.error(data?.message);
//       }
//     } catch (e) {
//       toast.error(e?.message);
//     }
//   };
//   return (
//     <Container>
//       <div className="row g-5 mt-2 mb-5">
//         <div className="col-md-5 col-lg-4 order-md-last">
//           <h4 className="d-flex justify-content-between align-items-center mb-3">
//             <span className="text-danger">{t("titles.cart")}</span>
//             <span className="badge bg-danger rounded-pill">
//               {products.length}
//             </span>
//           </h4>
//           <ul className="list-group mb-3">
//             {products.length > 0 &&
//               products.map(({ title, description, price }) => {
//                 return (
//                   <li className="list-group-item d-flex justify-content-between lh-sm">
//                     <div>
//                       <h6 className="my-0">{title}</h6>

//                       <small
//                         className={
//                           seeMore
//                             ? "text-muted"
//                             : "text-muted content-wrapper-landing"
//                         }
//                       >
//                         {description}
//                       </small>
//                       <p
//                         style={{
//                           color: "red",
//                           fontSize: "16px",
//                           cursor: "pointer",
//                         }}
//                         className="section-title"
//                         onClick={() => toggleActive()}
//                       >
//                         {seeMore ? "See Less" : "See More"}
//                       </p>
//                     </div>
//                     <span className="text-muted">
//                       ({price}) x ({state.sharePerPrice}) = $
//                       {price * state.sharePerPrice}
//                     </span>
//                   </li>
//                 );
//               })}

//             {/*<li className="list-group-item d-flex justify-content-between bg-light">
//                             <div className="text-success">
//                                 <h6 className="my-0">Promo code</h6>
//                                 <small>EXAMPLECODE</small>
//                             </div>
//                             <span className="text-success">−$5</span>
//                         </li>*/}
//             <li className="list-group-item d-flex justify-content-between">
//               <span>Total (USD)</span>
//               <strong>$ {totalPrice}</strong>
//             </li>
//           </ul>

//           {/* <form className="card p-2">
//                         <div className="input-group">
//                             <input type="text" className="form-control" placeholder="Promo code"/>
//                             <button type="submit" className="btn btn-secondary">Redeem</button>
//                         </div>
//                     </form> */}
//         </div>
//         <div className="col-md-7 col-lg-8">
//           <h4 className="mb-3">{t("titles.select-payment-method")}</h4>

//           <div
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "space-around",
//             }}
//           >
//             <label class="form-control-radio">
//               <input
//                 type="radio"
//                 name="radio"
//                 onChange={() => setTab("card")}
//                 value={tab}
//                 checked={tab === "card"}
//               />
//               By Card
//             </label>

//             <label class="form-control-radio">
//               <input
//                 type="radio"
//                 name="radio"
//                 onChange={() => setTab("wallet")}
//                 value={tab}
//                 checked={tab === "wallet"}
//               />
//               By Wallet
//             </label>
//           </div>

//           {/* <div>
//           <input type="radio" id="html" name="fav_language" value="HTML" />
//           <label className="mx-2" for="html">Cash</label>
//           </div>

//           <div>
//           <input  type="radio" id="html" name="fav_language" value="HTML" />
//           <label  for="html">Wallet</label>
//           </div>
//            */}
//         </div>

//         {tab === "card" ? (
//           <>
//             <div className="col-md-7 col-lg-8">
//               <h4 className="mb-3">{t("titles.checkout")}</h4>
//               <form
//                 id="billing-form"
//                 className="needs-validation"
//                 onSubmit={submitHandler}
//               >
//                 <div className="row g-3">
//                   <div className="col-sm-6">
//                     <label htmlFor="firstName" className="form-label">
//                       {t("labels.name")}
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="name"
//                       name="name"
//                       required
//                     />
//                     <div className="invalid-feedback">{t("messages.name")}</div>
//                   </div>

//                   <div className="col-sm-6">
//                     <label htmlFor="accountNo" className="form-label">
//                       {t("labels.accountNo")}
//                     </label>
//                     <input
//                       type="number"
//                       className="form-control"
//                       id="accountNo"
//                       name="accountNo"
//                       required
//                     />
//                     <div className="invalid-feedback">
//                       {t("messages.accountNo")}
//                     </div>
//                   </div>

//                   <div className="col-sm-6">
//                     <label htmlFor="referenceNo" className="form-label">
//                       {t("labels.referenceNo")}
//                     </label>
//                     <input
//                       type="number"
//                       className="form-control"
//                       id="referenceNo"
//                       name="referenceNo"
//                       required
//                     />
//                     <div className="invalid-feedback">
//                       {t("messages.referenceNo")}
//                     </div>
//                   </div>

//                   <div className="col-sm-6">
//                     <label htmlFor="email" className="form-label">
//                       {t("labels.email")}
//                       {/*<span className="text-muted">(Optional)</span>*/}
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="email"
//                       name="email"
//                       placeholder="you@example.com"
//                       required
//                     />
//                     <div className="invalid-feedback" />
//                     {/* {t("messages.email")} */}
//                   </div>

//                   {/*</div>*/}

//                   <div className="col-12">
//                     <label htmlFor="address" className="form-label">
//                       {t("labels.address")}
//                     </label>
//                     <textarea
//                       type="text"
//                       className="form-control"
//                       id="address"
//                       name="address"
//                       placeholder="1234 Main St"
//                       required
//                     />
//                     <div className="invalid-feedback">
//                       {t("messages.shipping_address")}
//                     </div>
//                   </div>

//                   {/*<div className="col-12">
//                                 <label htmlFor="address2" className="form-label">Address 2 <span
//                                     className="text-muted">(Optional)</span></label>
//                                 <input type="text" className="form-control" id="address2" placeholder="Apartment or suite"/>
//                             </div>*/}

//                   <div className="col-md-5">
//                     <label htmlFor="country" className="form-label">
//                       {t("labels.country")}
//                     </label>
//                     <select
//                       className="form-select"
//                       id="country"
//                       name="country"
//                       required
//                     >
//                       <option value="">{t("buttons.choose")}</option>
//                       {COUNTRY_LIST.map((item) => {
//                         return (
//                           <>
//                             {" "}
//                             <option>{item}</option>
//                           </>
//                         );
//                       })}
//                     </select>
//                     <div className="invalid-feedback">
//                       Please select a valid country.
//                     </div>
//                   </div>

//                   <div className="col-md-4">
//                     <label htmlFor="state" className="form-label">
//                       {t("labels.state")}
//                     </label>
//                     <select
//                       className="form-select"
//                       id="state"
//                       name="state"
//                       required
//                     >
//                       <option value="">{t("buttons.choose")}</option>
//                       <option value="LND">London, City of</option>
//                       <option value="ABE">Aberdeen City</option>
//                       <option value="ABD">Aberdeenshire</option>
//                       <option value="ANS">Angus</option>
//                       <option value="AGB">Argyll and Bute</option>
//                       <option value="CLK">Clackmannanshire</option>
//                       <option value="DGY">Dumfries and Galloway</option>
//                       <option value="DND">Dundee City</option>
//                       <option value="EAY">East Ayrshire</option>
//                       <option value="EDU">East Dunbartonshire</option>
//                       <option value="ELN">East Lothian</option>
//                       <option value="ERW">East Renfrewshire</option>
//                       <option value="EDH">Edinburgh, City of</option>
//                       <option value="ELS">Eilean Siar</option>
//                       <option value="FAL">Falkirk</option>
//                       <option value="FIF">Fife</option>
//                       <option value="GLG">Glasgow City</option>
//                       <option value="HLD">Highland</option>
//                       <option value="IVC">Inverclyde</option>
//                       <option value="MLN">Midlothian</option>
//                       <option value="MRY">Moray</option>
//                       <option value="NAY">North Ayrshire</option>
//                       <option value="NLK">North Lanarkshire</option>
//                       <option value="ORK">Orkney Islands</option>
//                       <option value="PKN">Perth and Kinross</option>
//                       <option value="RFW">Renfrewshire</option>
//                       <option value="SCB">Scottish Borders</option>
//                       <option value="ZET">Shetland Islands</option>
//                       <option value="SAY">South Ayrshire</option>
//                       <option value="SLK">South Lanarkshire</option>
//                       <option value="STG">Stirling</option>
//                       <option value="WDU">West Dunbartonshire</option>
//                       <option value="WLN">West Lothian</option>
//                       <option value="ANN">Antrim and Newtownabbey</option>
//                       <option value="AND">Ards and North Down</option>
//                       <option value="ABC">
//                         Armagh City, Banbridge and Craigavon
//                       </option>
//                       <option value="BFS">Belfast City</option>
//                       <option value="CCG">Causeway Coast and Glens</option>
//                       <option value="DRS">Derry and Strabane</option>
//                       <option value="FMO">Fermanagh and Omagh</option>
//                       <option value="LBC">Lisburn and Castlereagh</option>
//                       <option value="MEA">Mid and East Antrim</option>
//                       <option value="MUL">Mid-Ulster</option>
//                       <option value="NMD">Newry, Mourne and Down</option>
//                       <option value="BDG">Barking and Dagenham</option>
//                       <option value="BNE">Barnet</option>
//                       <option value="BEX">Bexley</option>
//                       <option value="BEN">Brent</option>
//                       <option value="BRY">Bromley</option>
//                       <option value="CMD">Camden</option>
//                       <option value="CRY">Croydon</option>
//                       <option value="EAL">Ealing</option>
//                       <option value="ENF">Enfield</option>
//                       <option value="GRE">Greenwich</option>
//                       <option value="HCK">Hackney</option>
//                       <option value="HMF">Hammersmith and Fulham</option>
//                       <option value="HRY">Haringey</option>
//                       <option value="HRW">Harrow</option>
//                       <option value="HAV">Havering</option>
//                       <option value="HIL">Hillingdon</option>
//                       <option value="HNS">Hounslow</option>
//                       <option value="ISL">Islington</option>
//                       <option value="KEC">Kensington and Chelsea</option>
//                       <option value="KTT">Kingston upon Thames</option>
//                       <option value="LBH">Lambeth</option>
//                       <option value="LEW">Lewisham</option>
//                       <option value="MRT">Merton</option>
//                       <option value="NWM">Newham</option>
//                       <option value="RDB">Redbridge</option>
//                       <option value="RIC">Richmond upon Thames</option>
//                       <option value="SWK">Southwark</option>
//                       <option value="STN">Sutton</option>
//                       <option value="TWH">Tower Hamlets</option>
//                       <option value="WFT">Waltham Forest</option>
//                       <option value="WND">Wandsworth</option>
//                       <option value="WSM">Westminster</option>
//                       <option value="BNS">Barnsley</option>
//                       <option value="BIR">Birmingham</option>
//                       <option value="BOL">Bolton</option>
//                       <option value="BRD">Bradford</option>
//                       <option value="BUR">Bury</option>
//                       <option value="CLD">Calderdale</option>
//                       <option value="COV">Coventry</option>
//                       <option value="DNC">Doncaster</option>
//                       <option value="DUD">Dudley</option>
//                       <option value="GAT">Gateshead</option>
//                       <option value="KIR">Kirklees</option>
//                       <option value="KWL">Knowsley</option>
//                       <option value="LDS">Leeds</option>
//                       <option value="LIV">Liverpool</option>
//                       <option value="MAN">Manchester</option>
//                       <option value="NET">Newcastle upon Tyne</option>
//                       <option value="NTY">North Tyneside</option>
//                       <option value="OLD">Oldham</option>
//                       <option value="RCH">Rochdale</option>
//                       <option value="ROT">Rotherham</option>
//                       <option value="SLF">Salford</option>
//                       <option value="SAW">Sandwell</option>
//                       <option value="SFT">Sefton</option>
//                       <option value="SHF">Sheffield</option>
//                       <option value="SOL">Solihull</option>
//                       <option value="STY">South Tyneside</option>
//                       <option value="SHN">St. Helens</option>
//                       <option value="SKP">Stockport</option>
//                       <option value="SND">Sunderland</option>
//                       <option value="TAM">Tameside</option>
//                       <option value="TRF">Trafford</option>
//                       <option value="WKF">Wakefield</option>
//                       <option value="WLL">Walsall</option>
//                       <option value="WGN">Wigan</option>
//                       <option value="WRL">Wirral</option>
//                       <option value="WLV">Wolverhampton</option>
//                       <option value="BKM">Buckinghamshire</option>
//                       <option value="CAM">Cambridgeshire</option>
//                       <option value="CMA">Cumbria</option>
//                       <option value="DBY">Derbyshire</option>
//                       <option value="DEV">Devon</option>
//                       <option value="DOR">Dorset</option>
//                       <option value="ESX">East Sussex</option>
//                       <option value="ESS">Essex</option>
//                       <option value="GLS">Gloucestershire</option>
//                       <option value="HAM">Hampshire</option>
//                       <option value="HRT">Hertfordshire</option>
//                       <option value="KEN">Kent</option>
//                       <option value="LAN">Lancashire</option>
//                       <option value="LEC">Leicestershire</option>
//                       <option value="LIN">Lincolnshire</option>
//                       <option value="NFK">Norfolk</option>
//                       <option value="NYK">North Yorkshire</option>
//                       <option value="NTH">Northamptonshire</option>
//                       <option value="NTT">Nottinghamshire</option>
//                       <option value="OXF">Oxfordshire</option>
//                       <option value="SOM">Somerset</option>
//                       <option value="STS">Staffordshire</option>
//                       <option value="SFK">Suffolk</option>
//                       <option value="SRY">Surrey</option>
//                       <option value="WAR">Warwickshire</option>
//                       <option value="WSX">West Sussex</option>
//                       <option value="WOR">Worcestershire</option>
//                       <option value="BAS">Bath and North East Somerset</option>
//                       <option value="BDF">Bedford</option>
//                       <option value="BBD">Blackburn with Darwen</option>
//                       <option value="BPL">Blackpool</option>
//                       <option value="BGW">Blaenau Gwent</option>
//                       <option value="BCP">
//                         Bournemouth, Christchurch and Poole
//                       </option>
//                       <option value="BRC">Bracknell Forest</option>
//                       <option value="BGE">
//                         Bridgend [Pen-y-bont ar Ogwr GB-POG]
//                       </option>
//                       <option value="BNH">Brighton and Hove</option>
//                       <option value="BST">Bristol, City of</option>
//                       <option value="CAY">Caerphilly [Caerffili GB-CAF]</option>
//                       <option value="CRF">Cardiff [Caerdydd GB-CRD]</option>
//                       <option value="CMN">
//                         Carmarthenshire [Sir Gaerfyrddin GB-GFY]
//                       </option>
//                       <option value="CBF">Central Bedfordshire</option>
//                       <option value="CGN">Ceredigion [Sir Ceredigion]</option>
//                       <option value="CHE">Cheshire East</option>
//                       <option value="CHW">Cheshire West and Chester</option>
//                       <option value="CWY">Conwy</option>
//                       <option value="CON">Cornwall</option>
//                       <option value="DAL">Darlington</option>
//                       <option value="DEN">
//                         Denbighshire [Sir Ddinbych GB-DDB]
//                       </option>
//                       <option value="DER">Derby</option>
//                       <option value="DUR">Durham, County</option>
//                       <option value="ERY">East Riding of Yorkshire</option>
//                       <option value="FLN">
//                         Flintshire [Sir y Fflint GB-FFL]
//                       </option>
//                       <option value="GWN">Gwynedd</option>
//                       <option value="HAL">Halton</option>
//                       <option value="HPL">Hartlepool</option>
//                       <option value="HEF">Herefordshire</option>
//                       <option value="AGY">
//                         Isle of Anglesey [Sir Ynys Môn GB-YNM]
//                       </option>
//                       <option value="IOW">Isle of Wight</option>
//                       <option value="IOS">Isles of Scilly</option>
//                       <option value="KHL">Kingston upon Hull</option>
//                       <option value="LCE">Leicester</option>
//                       <option value="LUT">Luton</option>
//                       <option value="MDW">Medway</option>
//                       <option value="MTY">
//                         Merthyr Tydfil [Merthyr Tudful GB-MTU]
//                       </option>
//                       <option value="MDB">Middlesbrough</option>
//                       <option value="MIK">Milton Keynes</option>
//                       <option value="MON">
//                         Monmouthshire [Sir Fynwy GB-FYN]
//                       </option>
//                       <option value="NTL">
//                         Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]
//                       </option>
//                       <option value="NWP">Newport [Casnewydd GB-CNW]</option>
//                       <option value="NEL">North East Lincolnshire</option>
//                       <option value="NLN">North Lincolnshire</option>
//                       <option value="NSM">North Somerset</option>
//                       <option value="NBL">Northumberland</option>
//                       <option value="NGM">Nottingham</option>
//                       <option value="PEM">
//                         Pembrokeshire [Sir Benfro GB-BNF]
//                       </option>
//                       <option value="PTE">Peterborough</option>
//                       <option value="PLY">Plymouth</option>
//                       <option value="POR">Portsmouth</option>
//                       <option value="POW">Powys</option>
//                       <option value="RDG">Reading</option>
//                       <option value="RCC">Redcar and Cleveland</option>
//                       <option value="RCT">
//                         Rhondda Cynon Taff [Rhondda CynonTaf]
//                       </option>
//                       <option value="RUT">Rutland</option>
//                       <option value="SHR">Shropshire</option>
//                       <option value="SLG">Slough</option>
//                       <option value="SGC">South Gloucestershire</option>
//                       <option value="STH">Southampton</option>
//                       <option value="SOS">Southend-on-Sea</option>
//                       <option value="STT">Stockton-on-Tees</option>
//                       <option value="STE">Stoke-on-Trent</option>
//                       <option value="SWA">Swansea [Abertawe GB-ATA]</option>
//                       <option value="SWD">Swindon</option>
//                       <option value="TFW">Telford and Wrekin</option>
//                       <option value="THR">Thurrock</option>
//                       <option value="TOB">Torbay</option>
//                       <option value="TOF">Torfaen [Tor-faen]</option>
//                       <option value="VGL">
//                         Vale of Glamorgan, The [Bro Morgannwg GB-BMG]
//                       </option>
//                       <option value="WRT">Warrington</option>
//                       <option value="WBK">West Berkshire</option>
//                       <option value="WIL">Wiltshire</option>
//                       <option value="WNM">Windsor and Maidenhead</option>
//                       <option value="WOK">Wokingham</option>
//                       <option value="WRX">Wrexham [Wrecsam GB-WRC]</option>
//                       <option value="YOR">York</option>
//                       <option value="LND">London, City of</option>
//                       <option value="ABE">Aberdeen City</option>
//                       <option value="ABD">Aberdeenshire</option>
//                       <option value="ANS">Angus</option>
//                       <option value="AGB">Argyll and Bute</option>
//                       <option value="CLK">Clackmannanshire</option>
//                       <option value="DGY">Dumfries and Galloway</option>
//                       <option value="DND">Dundee City</option>
//                       <option value="EAY">East Ayrshire</option>
//                       <option value="EDU">East Dunbartonshire</option>
//                       <option value="ELN">East Lothian</option>
//                       <option value="ERW">East Renfrewshire</option>
//                       <option value="EDH">Edinburgh, City of</option>
//                       <option value="ELS">Eilean Siar</option>
//                       <option value="FAL">Falkirk</option>
//                       <option value="FIF">Fife</option>
//                       <option value="GLG">Glasgow City</option>
//                       <option value="HLD">Highland</option>
//                       <option value="IVC">Inverclyde</option>
//                       <option value="MLN">Midlothian</option>
//                       <option value="MRY">Moray</option>
//                       <option value="NAY">North Ayrshire</option>
//                       <option value="NLK">North Lanarkshire</option>
//                       <option value="ORK">Orkney Islands</option>
//                       <option value="PKN">Perth and Kinross</option>
//                       <option value="RFW">Renfrewshire</option>
//                       <option value="SCB">Scottish Borders</option>
//                       <option value="ZET">Shetland Islands</option>
//                       <option value="SAY">South Ayrshire</option>
//                       <option value="SLK">South Lanarkshire</option>
//                       <option value="STG">Stirling</option>
//                       <option value="WDU">West Dunbartonshire</option>
//                       <option value="WLN">West Lothian</option>
//                       <option value="ANN">Antrim and Newtownabbey</option>
//                       <option value="AND">Ards and North Down</option>
//                       <option value="ABC">
//                         Armagh City, Banbridge and Craigavon
//                       </option>
//                       <option value="BFS">Belfast City</option>
//                       <option value="CCG">Causeway Coast and Glens</option>
//                       <option value="DRS">Derry and Strabane</option>
//                       <option value="FMO">Fermanagh and Omagh</option>
//                       <option value="LBC">Lisburn and Castlereagh</option>
//                       <option value="MEA">Mid and East Antrim</option>
//                       <option value="MUL">Mid-Ulster</option>
//                       <option value="NMD">Newry, Mourne and Down</option>
//                       <option value="BDG">Barking and Dagenham</option>
//                       <option value="BNE">Barnet</option>
//                       <option value="BEX">Bexley</option>
//                       <option value="BEN">Brent</option>
//                       <option value="BRY">Bromley</option>
//                       <option value="CMD">Camden</option>
//                       <option value="CRY">Croydon</option>
//                       <option value="EAL">Ealing</option>
//                       <option value="ENF">Enfield</option>
//                       <option value="GRE">Greenwich</option>
//                       <option value="HCK">Hackney</option>
//                       <option value="HMF">Hammersmith and Fulham</option>
//                       <option value="HRY">Haringey</option>
//                       <option value="HRW">Harrow</option>
//                       <option value="HAV">Havering</option>
//                       <option value="HIL">Hillingdon</option>
//                       <option value="HNS">Hounslow</option>
//                       <option value="ISL">Islington</option>
//                       <option value="KEC">Kensington and Chelsea</option>
//                       <option value="KTT">Kingston upon Thames</option>
//                       <option value="LBH">Lambeth</option>
//                       <option value="LEW">Lewisham</option>
//                       <option value="MRT">Merton</option>
//                       <option value="NWM">Newham</option>
//                       <option value="RDB">Redbridge</option>
//                       <option value="RIC">Richmond upon Thames</option>
//                       <option value="SWK">Southwark</option>
//                       <option value="STN">Sutton</option>
//                       <option value="TWH">Tower Hamlets</option>
//                       <option value="WFT">Waltham Forest</option>
//                       <option value="WND">Wandsworth</option>
//                       <option value="WSM">Westminster</option>
//                       <option value="BNS">Barnsley</option>
//                       <option value="BIR">Birmingham</option>
//                       <option value="BOL">Bolton</option>
//                       <option value="BRD">Bradford</option>
//                       <option value="BUR">Bury</option>
//                       <option value="CLD">Calderdale</option>
//                       <option value="COV">Coventry</option>
//                       <option value="DNC">Doncaster</option>
//                       <option value="DUD">Dudley</option>
//                       <option value="GAT">Gateshead</option>
//                       <option value="KIR">Kirklees</option>
//                       <option value="KWL">Knowsley</option>
//                       <option value="LDS">Leeds</option>
//                       <option value="LIV">Liverpool</option>
//                       <option value="MAN">Manchester</option>
//                       <option value="NET">Newcastle upon Tyne</option>
//                       <option value="NTY">North Tyneside</option>
//                       <option value="OLD">Oldham</option>
//                       <option value="RCH">Rochdale</option>
//                       <option value="ROT">Rotherham</option>
//                       <option value="SLF">Salford</option>
//                       <option value="SAW">Sandwell</option>
//                       <option value="SFT">Sefton</option>
//                       <option value="SHF">Sheffield</option>
//                       <option value="SOL">Solihull</option>
//                       <option value="STY">South Tyneside</option>
//                       <option value="SHN">St. Helens</option>
//                       <option value="SKP">Stockport</option>
//                       <option value="SND">Sunderland</option>
//                       <option value="TAM">Tameside</option>
//                       <option value="TRF">Trafford</option>
//                       <option value="WKF">Wakefield</option>
//                       <option value="WLL">Walsall</option>
//                       <option value="WGN">Wigan</option>
//                       <option value="WRL">Wirral</option>
//                       <option value="WLV">Wolverhampton</option>
//                       <option value="BKM">Buckinghamshire</option>
//                       <option value="CAM">Cambridgeshire</option>
//                       <option value="CMA">Cumbria</option>
//                       <option value="DBY">Derbyshire</option>
//                       <option value="DEV">Devon</option>
//                       <option value="DOR">Dorset</option>
//                       <option value="ESX">East Sussex</option>
//                       <option value="ESS">Essex</option>
//                       <option value="GLS">Gloucestershire</option>
//                       <option value="HAM">Hampshire</option>
//                       <option value="HRT">Hertfordshire</option>
//                       <option value="KEN">Kent</option>
//                       <option value="LAN">Lancashire</option>
//                       <option value="LEC">Leicestershire</option>
//                       <option value="LIN">Lincolnshire</option>
//                       <option value="NFK">Norfolk</option>
//                       <option value="NYK">North Yorkshire</option>
//                       <option value="NTH">Northamptonshire</option>
//                       <option value="NTT">Nottinghamshire</option>
//                       <option value="OXF">Oxfordshire</option>
//                       <option value="SOM">Somerset</option>
//                       <option value="STS">Staffordshire</option>
//                       <option value="SFK">Suffolk</option>
//                       <option value="SRY">Surrey</option>
//                       <option value="WAR">Warwickshire</option>
//                       <option value="WSX">West Sussex</option>
//                       <option value="WOR">Worcestershire</option>
//                       <option value="BAS">Bath and North East Somerset</option>
//                       <option value="BDF">Bedford</option>
//                       <option value="BBD">Blackburn with Darwen</option>
//                       <option value="BPL">Blackpool</option>
//                       <option value="BGW">Blaenau Gwent</option>
//                       <option value="BCP">
//                         Bournemouth, Christchurch and Poole
//                       </option>
//                       <option value="BRC">Bracknell Forest</option>
//                       <option value="BGE">
//                         Bridgend [Pen-y-bont ar Ogwr GB-POG]
//                       </option>
//                       <option value="BNH">Brighton and Hove</option>
//                       <option value="BST">Bristol, City of</option>
//                       <option value="CAY">Caerphilly [Caerffili GB-CAF]</option>
//                       <option value="CRF">Cardiff [Caerdydd GB-CRD]</option>
//                       <option value="CMN">
//                         Carmarthenshire [Sir Gaerfyrddin GB-GFY]
//                       </option>
//                       <option value="CBF">Central Bedfordshire</option>
//                       <option value="CGN">Ceredigion [Sir Ceredigion]</option>
//                       <option value="CHE">Cheshire East</option>
//                       <option value="CHW">Cheshire West and Chester</option>
//                       <option value="CWY">Conwy</option>
//                       <option value="CON">Cornwall</option>
//                       <option value="DAL">Darlington</option>
//                       <option value="DEN">
//                         Denbighshire [Sir Ddinbych GB-DDB]
//                       </option>
//                       <option value="DER">Derby</option>
//                       <option value="DUR">Durham, County</option>
//                       <option value="ERY">East Riding of Yorkshire</option>
//                       <option value="FLN">
//                         Flintshire [Sir y Fflint GB-FFL]
//                       </option>
//                       <option value="GWN">Gwynedd</option>
//                       <option value="HAL">Halton</option>
//                       <option value="HPL">Hartlepool</option>
//                       <option value="HEF">Herefordshire</option>
//                       <option value="AGY">
//                         Isle of Anglesey [Sir Ynys Môn GB-YNM]
//                       </option>
//                       <option value="IOW">Isle of Wight</option>
//                       <option value="IOS">Isles of Scilly</option>
//                       <option value="KHL">Kingston upon Hull</option>
//                       <option value="LCE">Leicester</option>
//                       <option value="LUT">Luton</option>
//                       <option value="MDW">Medway</option>
//                       <option value="MTY">
//                         Merthyr Tydfil [Merthyr Tudful GB-MTU]
//                       </option>
//                       <option value="MDB">Middlesbrough</option>
//                       <option value="MIK">Milton Keynes</option>
//                       <option value="MON">
//                         Monmouthshire [Sir Fynwy GB-FYN]
//                       </option>
//                       <option value="NTL">
//                         Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]
//                       </option>
//                       <option value="NWP">Newport [Casnewydd GB-CNW]</option>
//                       <option value="NEL">North East Lincolnshire</option>
//                       <option value="NLN">North Lincolnshire</option>
//                       <option value="NSM">North Somerset</option>
//                       <option value="NBL">Northumberland</option>
//                       <option value="NGM">Nottingham</option>
//                       <option value="PEM">
//                         Pembrokeshire [Sir Benfro GB-BNF]
//                       </option>
//                       <option value="PTE">Peterborough</option>
//                       <option value="PLY">Plymouth</option>
//                       <option value="POR">Portsmouth</option>
//                       <option value="POW">Powys</option>
//                       <option value="RDG">Reading</option>
//                       <option value="RCC">Redcar and Cleveland</option>
//                       <option value="RCT">
//                         Rhondda Cynon Taff [Rhondda CynonTaf]
//                       </option>
//                       <option value="RUT">Rutland</option>
//                       <option value="SHR">Shropshire</option>
//                       <option value="SLG">Slough</option>
//                       <option value="SGC">South Gloucestershire</option>
//                       <option value="STH">Southampton</option>
//                       <option value="SOS">Southend-on-Sea</option>
//                       <option value="STT">Stockton-on-Tees</option>
//                       <option value="STE">Stoke-on-Trent</option>
//                       <option value="SWA">Swansea [Abertawe GB-ATA]</option>
//                       <option value="SWD">Swindon</option>
//                       <option value="TFW">Telford and Wrekin</option>
//                       <option value="THR">Thurrock</option>
//                       <option value="TOB">Torbay</option>
//                       <option value="TOF">Torfaen [Tor-faen]</option>
//                       <option value="VGL">
//                         Vale of Glamorgan, The [Bro Morgannwg GB-BMG]
//                       </option>
//                       <option value="WRT">Warrington</option>
//                       <option value="WBK">West Berkshire</option>
//                       <option value="WIL">Wiltshire</option>
//                       <option value="WNM">Windsor and Maidenhead</option>
//                       <option value="WOK">Wokingham</option>
//                       <option value="WRX">Wrexham [Wrecsam GB-WRC]</option>
//                       <option value="YOR">York</option>
//                     </select>
//                     <div className="invalid-feedback">
//                       Please provide a valid state.
//                     </div>
//                   </div>

//                   <div className="col-md-3">
//                     <label htmlFor="zip" className="form-label">
//                       {t("labels.zip")}
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="zip"
//                       name="state"
//                       required
//                     />
//                     <div className="invalid-feedback">Zip code required.</div>
//                   </div>
//                 </div>

//                 {/* <hr className="my-4"/> */}

//                 {/*<div className="form-check">
//                             <input type="checkbox" className="form-check-input" id="same-address"/>
//                             <label className="form-check-label" htmlFor="same-address">Shipping address is the same
//                                 as my billing address</label>
//                         </div>

//                         <div className="form-check">
//                             <input type="checkbox" className="form-check-input" id="save-info"/>
//                             <label className="form-check-label" htmlFor="save-info">Save this information for next
//                                 time</label>
//                         </div>*/}

//                 {/*<hr className="my-4"/>*/}

//                 {/* <h4 className="mb-3">Payment</h4>

//                         <div className="my-3">
//                             <div className="form-check">
//                                 <input id="credit" name="paymentMethod" type="radio" className="form-check-input"
//                                         value="Credit Card" />
//                                 <label className="form-check-label" htmlFor="credit">Credit card</label>
//                             </div>
//                             <div className="form-check">
//                                 <input id="debit" name="paymentMethod" type="radio" className="form-check-input"
//                                        value="Debit card"/>
//                                 <label className="form-check-label" htmlFor="debit">Debit card</label>
//                             </div>
//                             <div className="form-check">
//                                 <input id="paypal" name="paymentMethod" type="radio" className="form-check-input"
//                                        value="PayPal"/>
//                                 <label className="form-check-label" htmlFor="paypal">PayPal</label>
//                             </div>
//                         </div>

//                         <div className="row gy-3">
//                             <div className="col-md-6">
//                                 <label htmlFor="cc-name" className="form-label">Name on card</label>
//                                 <input type="text" className="form-control" id="cc-name" name="cc-name" required/>
//                                 <small className="text-muted">Full name as displayed on card</small>
//                                 <div className="invalid-feedback">
//                                     Name on card is required
//                                 </div>
//                             </div>

//                             <div className="col-md-6">
//                                 <label htmlFor="cc-number" className="form-label">Credit card number</label>
//                                 <input type="number" className="form-control" id="cc-number" name="cc-number" required/>
//                                 <div className="invalid-feedback">
//                                     Credit card number is required
//                                 </div>
//                             </div>

//                             <div className="col-md-3">
//                                 <label htmlFor="cc-expiration" className="form-label">Expiration</label>
//                                 <input type="text" className="form-control" id="cc-expiration" name="cc-expiration" required/>
//                                 <div className="invalid-feedback">
//                                     Expiration date required
//                                 </div>
//                             </div>

//                             <div className="col-md-3">
//                                 <label htmlFor="cc-cvv" className="form-label">CVV</label>
//                                 <input type="number" className="form-control" id="cc-cvv" name="cc-cvv" required/>
//                                 <div className="invalid-feedback">
//                                     Security code required
//                                 </div>
//                             </div>
//                         </div>

//                         <hr className="my-4"/> */}

//                 <button className="lp-btn full-btn mt-4 p-2" type="submit">
//                   {t("buttons.checkout")}
//                 </button>
//               </form>
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="col-md-7 col-lg-8">
//               <h4 className="mb-3">{t("titles.checkoutwallet")}</h4>
//               <Grid item xs={12} sm={6} md={4} lg={3}>
//                 <Paper
//                   sx={{
//                     p: 2,
//                     display: "flex",
//                     flexDirection: "column",
//                   }}
//                   className="dashboard-box mt-5"
//                 >
//                   <h3 className="text-center">Balance in wallet</h3>
//                   <h4>{wallet?.dividend.toFixed(5) || 0}</h4>
//                 </Paper>
//               </Grid>
//               <button
//                 className="lp-btn full-btn mt-4 p-2"
//                 onClick={() => submitHandlerWallet()}
//               >
//                 {t("buttons.checkout")}
//               </button>
//             </div>
//           </>
//         )}
//       </div>
//     </Container>
//   );
// };

// export default CheckoutForm;

import { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useNavigate from "hooks/Navigate";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import imageUrl from "utils/imageUrl";
const CheckoutForm = () => {
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = useState(false);
  const [prices, setPrices] = useState({});

  const [showDiv, setShowDiv] = useState(false);

  const toggleActive = () => {
    setSeeMore(!seeMore);
  };

  const [loader, setLoader] = useState(false);
  const { state } = useLocation();
  const { t } = useTranslation();

  const { user } = useSelector((store) => store.user);

  const [tab, setTab] = useState("card");

  useEffect(() => {
    if (!state) {
      navigate("/404");
    }
  }, []);

  const products = state ? [state] : [];

  useEffect(() => {
    calculateValues(products);
  }, []);

  const calculateValues = (products) => {
    let total = products.reduce((sum, { price }) => {
      sum += price;
      return sum;
    }, 0);

    const user = Math.floor(total * 0.7);
    const owner = Math.floor(total * 0.3);
    total = state?.price;

    setPrices({ user, owner, total });
  };

  const totalPrice = useMemo(() => {
    const storePrice = products.reduce((sum, store) => {
      sum += store?.price || 0;
      return sum;
    }, 0);

    return storePrice * state?.sharePerPrice;
  }, []);

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    setShowDiv(true);

    setTimeout(() => {
      setShowDiv(false);
    }, 1000);
  };

  return (
    <Container>
      <div></div>
      <div className="row g-5 mt-2 mb-5">
        <h4 className="mb-3"> {t("titles.select-payment-method")}</h4>
      </div>

      {showDiv && (
        <div className={`transition-div ${showDiv ? "show" : ""}`}>
          <p>copy successfully</p>
        </div>
      )}

      {/* <div class="container">
        <div class="row flex-wrap">
          <div class="col-lg-7">
            <div class="d-flex flex-column h-100">
              <div class="flex-grow-1 terms-condition">
     
                <label class="form-control-radio mb-4">
                  SPEI
                </label>

                <label class="form-control-radio checkout-terms">
              <input
                type="checkbox"
                name="terms"
                required
              />
              {t("titles.checkout_terms_conditions")}
            </label>
        



              </div>
            </div>
          </div>
          </div>
      


      </div> */}

      <div class="container">
        <div class="row flex-wrap-reverse">
          <div class="col-lg-8">
            <div class="d-flex flex-column h-100">
              <div class="flex-grow-1">
                <h5 className="mt-3">{t("titles.checkout_info")}</h5>

                <div className="d-flex align-items-center mt-5 mb-4 info-detail-step">
                  {/* <div class="circle-checkout">1</div> */}

                  <img
                    src={imageUrl("info.png")}
                    className="circle-checkout"
                    alt="info"
                  />
                  <h6>{t("titles.steps1")}</h6>
                </div>

                <div className="d-flex align-items-center mb-3 info-detail-step">
                  {/* <div class="circle-checkout">2</div> */}

                  <img
                    src={imageUrl("info.png")}
                    className="circle-checkout"
                    alt="info"
                  />

                  <h6>{t("titles.steps2")}</h6>
                </div>

                <div className="d-flex align-items-center mb-3 info-detail-step">
                  {/* <div class="circle-checkout">3</div> */}

                  <img
                    src={imageUrl("info.png")}
                    className="circle-checkout"
                    alt="info"
                  />

                  <h6>{t("titles.steps3")}</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <h4 className="d-flex justify-content-center align-items-center cart-box">
              <span>{t("titles.cart")}</span>
              {/* <span className="badge bg-danger rounded-pill">
                {products.length}
              </span> */}
            </h4>

            <ul className="list-group mb-3">
              {products.length > 0 &&
                products.map(({ title, description, price }) => {
                  return (
                    <li
                      style={{ flexWrap: "wrap" }}
                      className="list-group-item d-flex justify-content-between lh-sm"
                    >
                      <div>
                        <h6 className="my-0">{title}</h6>

                        <small
                          className={
                            seeMore
                              ? "text-muted"
                              : "text-muted content-wrapper-landing"
                          }
                        >
                          {description}
                        </small>
                        <p
                          style={{
                            color: "red",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          className="section-title"
                          onClick={() => toggleActive()}
                        >
                          {seeMore ? "See Less" : "See More"}
                        </p>
                      </div>
                      <span className="text-muted">
                        ({price}) x ({state.sharePerPrice}) = $
                        {price * state.sharePerPrice}
                      </span>
                    </li>
                  );
                })}

              <li className="list-group-item d-flex justify-content-between">
                <span>Total (MXN)</span>
                <strong>$ {totalPrice}</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="container mb-5">
        <div class="row flex-wrap-reverse">
          <div class="col-lg-8">
            <div class="d-flex flex-column h-100">
              <div class="flex-grow-1">
                <div>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <label className="form-label black-checkout-name">
                        {t("labels.accountName")}
                      </label>
                      <div className="checkout-field-box">
                        <span>Bank Account</span>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label className="form-label black-checkout-name">
                        {t("labels.beneficiary")}
                      </label>
                      <div className="checkout-field-box">
                        <span>{user?.name}</span>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label className="form-label black-checkout-name">
                        {t("labels.destination")}
                      </label>

                      <div className="checkout-field-box">
                        <span>STP (Transfer and Payment System) </span>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <label className="form-label black-checkout-name">
                        {t("labels.clabe")}
                      </label>
                      <div className="d-flex justify-content-between checkout-field-box">
                        <span>{user?.clabe}</span>
                        <p
                          className="copy-clabe"
                          onClick={() => {
                            copyToClipboard(user?.clabe);
                          }}
                        >
                          Copy&nbsp;
                          <ContentCopyIcon />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-3">
                    <span style={{ color: "#565656" }}>
                      {t("descs.transfer_warning")}
                    </span>
                  </div>

                  <div
                    className="col-lg-12 mt-3 p-3"
                    style={{
                      backgroundColor: "rgba(224, 32, 28, 0.1)",
                      borderRadius: "10px",
                    }}
                  >
                    <span
                      style={{
                        color: "#E0201C",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      {t("descs.invest_warning")}
                    </span>
                  </div>

                  {/* <button className="lp-btn full-btn mt-4 p-2" type="submit">
                      {t("buttons.checkout")}
                    </button> */}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div className="card card-shares-investor  mt-4 p-3">
              <div className="d-flex flex-column align-items-center">
                <h5>{t("titles.distribution_shares")}</h5>

                <h6 className={"mt-3"}>
                  70% = ({prices?.user} Shares) = Investor
                </h6>

                <h6 className={"mt-3"}>
                  30% = ({prices?.owner} Shares) = Founders
                </h6>

                <h6 className={"mt-4"}>TOTAL = ({prices?.total} Shares)</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CheckoutForm;

import { useEffect, useState } from "react";
import reduxApis from "redux/apis";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useNavigate from "hooks/Navigate";
import { Alert, Button, Col, Container, FormText, Row } from "react-bootstrap";
import { Header, Sidebar } from "components";
import { useTranslation } from "react-i18next";
import Link from "hooks/Link";
import { SUB_ADMIN_TYPES } from "assets/constants";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import SettingsPhoneOutlinedIcon from "@mui/icons-material/SettingsPhoneOutlined";
import { Form, InputGroup } from "react-bootstrap";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginMode, setLoginMode] = useState("email");

  const [rememberMe, setRememberMe] = useState(false);
  let email = localStorage.getItem("email");
  let password = localStorage.getItem("password");

  const handleLoginMode = (e) => {
    setLoginMode(e.target.id);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const [formData, setFormData] = useState({ email: "", password: "" });
  const { language, setLanguage, t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { user, loading } = useSelector((store) => store.user);
  const { adminUser } = useSelector((store) => store.admin);

  useEffect(() => {
    if(email && password)setFormData((prevState) => ({ ...prevState, email, password }));
    
    if (adminUser?.type === "owner") {
      navigate("/admin/dashboard");
    } else if (SUB_ADMIN_TYPES.includes(adminUser?.type)) {
      navigate("/sub-admin/dashboard");
    } else if (user) {
      navigate("/");
    } else if (adminUser) {
      navigate("/");
    }
  }, []);

  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const onSubmitHandler = async (e) => {
    if (rememberMe) {
      localStorage.setItem("email", formData.email);
      localStorage.setItem("password", formData.password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    e.preventDefault();
    try {
      const response = await dispatch(reduxApis.userLogin(formData));
      if (response?.payload?.status)
      {
         if(response?.payload?.data?.message === "We have sent you an OTP via email for verification.!")
         {
          setTimeout(() => navigate("/verify",{state : {email:formData?.email}}), 2000);
         }
        else if(response?.payload?.data?.user?.isVerified)
        {
          navigate("/user/dashboard");
        }
      }
    } catch (error) {
      console.log("Error in Login", error);
    }
  };

  return (

    <div className="New-login-form-main">
     {/* <Header /> */}
      <div className="New-login-form-inner">
  
        <div className="New-login-form-Content">
          <div>
            <div>
              <p className="New-login-bold-text">{t("descs.Login_desc_1")}</p>
              <p className="New-login-normal-text">{t("descs.Login_desc_2")}</p>
            </div>
            <div>
              <iframe
                className="mt-2 login-youtube-video"
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/13bmTsSj54I"
                title="YouTube video player"
                frameborder=""
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="New-login-form-form">
          <div className="New-login-form-inside">
            <div className="Centre-the-content">
              <img
              onClick={()=>navigate("/")}
                className="New-login-form-logo"
                src={require("../assets/images/logo.webp")}
                alt=""
              />
              <p className="New-login-form-heading">
                {t("titles.Login_Form_title1")}
              </p>
              <p className="New-login-form-heading-2">Java Times Caffe</p>
            </div>
            {/* <div className='Login-type-btn-container'>
              <button id='email' onClick={(e) => handleLoginMode(e)} className={`Login-type-btn ${loginMode === "email" ? "button-red-active" : ""}`}>{t("labels.email")}</button>
              <button id='tel' onClick={(e) => handleLoginMode(e)} className={`Login-type-btn ${loginMode === "tel" ? "button-red-active" : ""}`}>{t("labels.phone")}</button>
            </div> */}
            <div>
              <Form onSubmit={onSubmitHandler}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label className="Login-type-btn">
                    {t("labels.email")}
                  </Form.Label>

                  <InputGroup>
                    <InputGroup.Text className="Input-field-icons-body">
                      {loginMode === "email" ? (
                        <EmailOutlinedIcon className="Input-field-icons" />
                      ) : (
                        <SettingsPhoneOutlinedIcon className="Input-field-icons" />
                      )}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={onChangeHandler}
                      value={formData.email}
                      required
                      className="Input-custom-for-login"
                      type={`${loginMode}`}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Label className="Login-type-btn">
                    {t("placeholders.password")}
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text className="Input-field-icons-body">
                      <LockOpenOutlinedIcon className="Input-field-icons" />
                    </InputGroup.Text>
                    <Form.Control
                      className="Input-custom-for-login"
                      type={showPassword ? "text" : "password"}
                      onChange={onChangeHandler}
                      value={formData.password}
                      required
                    />
                    <InputGroup.Text
                      className="Input-field-icons-body"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon className="Input-field-icons" />
                      ) : (
                        <VisibilityOffOutlinedIcon className="Input-field-icons" />
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <div className="Form-checkbox-holder">
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label={`${t("titles.remember_me")}`}
                      onChange={handleRememberMe}
                    />
                  </Form.Group>
                  <div>
                    <button className="forget-password-btn">
                      <Link to="/forget">{t("titles.forgetPass")}</Link>
                    </button>
                  </div>
                </div>
                <div className="Login-btn-container">
                  <button type="submit" disabled={loading}>
                    {loading ? t("buttons.logging_in") : t("buttons.login")}
                  </button>
                </div>
                <div className="Centre-the-content m-4 text-12px">
                  {t("descs.Dont_have_an_account")}
                  <button className="signup-text-login-form">
                    <Link to="/registration">{t("buttons.signup")}</Link>{" "}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

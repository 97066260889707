import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

import useNavigate from "hooks/Navigate";
import Link from 'hooks/Link';
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import "../assets/css/header.css";
import { Logo, WalletCardIcon } from "./Images";
import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";

import i18next from "i18next";
import { logout, setTotalUnseenNotifications, setUserNotifications, setUserShowDropdown } from "redux/slices/userSlice";
import { adminLogout, setAdminNotifications, setShowDropdown, setTotalUnseenAdminNotifications } from "redux/slices/adminSlice";
import { PersonOutlined } from "@mui/icons-material";
import UserProfileModal from "./modals/UserProfileModal";
import { useTranslation } from "react-i18next";
import utils from "../utils";
import { walletNull } from "redux/slices/walletSlicer";
import apis from "services";
import imageUrl from "utils/imageUrl";
import UserNotification from "./UserNotification";
import { MdNotifications } from "react-icons/md";
import { useLocation } from "react-router-dom";

export function BasicCard({
  name,
  image,
  lastName,
  phone,
  type,
  email,
  close,
  isVerified,
  toggleProfileModal,
  walletId,
  reserveWalletId,
  ...restProps
}) {
  const { firstName, isAdmin } = restProps;

  const { t } = useTranslation();

  const logoutUser = async () => {
    const res = await apis.logoutUser();
  };

  const dispatch = useDispatch();

  if (isAdmin)
    return (
      <Card sx={{ minWidth: 275, padding: "20px 0" }} className="profile-card">
        <div className="text-center">
          <IconButton color="inherit" variant="contained">
            <Avatar sx={{ bgcolor: "#F1C9C9", color: "#E60E10" }}>
              {firstName.at(0)}
            </Avatar>
          </IconButton>
        </div>
        <div className="text-center py-2">
          <Typography variant="h5" component="div">
            Wallet Id
          </Typography>
          <Typography variant="p" component="div">
            {walletId}
          </Typography>
        </div>
        {type === "owner" &&
          <div className="text-center">
            <Typography variant="h5" component="div">
              Reserve Wallet Id
            </Typography>
            <Typography variant="p" component="div">
              {reserveWalletId}
            </Typography>
          </div>
        }

        <CardContent className="text-center">
          <Typography variant="h5" component="div">
            {firstName}
          </Typography>
          <Typography variant="p" component="div">
            {email}
          </Typography>
        </CardContent>
        <Link to={"/admin/dashboard"} style={{ textDecoration: "none" }}>
          <div className="card-wallet d-flex my-1">
            <div className="modal-li-flex">
              <DashboardIcon sx={{ color: "#E60E10" }} />
              <Typography
                variant="p"
                component="span"
                className="mx-3"
                color="#E60E10"
              >
                {t("buttons.dashboard")}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="span" color="#E60E10">
                {/*{walletData?.wallet?.map((item) => (
                                <h3>{item?.balance}</h3>
                            ))}*/}
              </Typography>
            </div>
          </div>
        </Link>

        {/*<Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
                    <div className="card-wallet d-flex  my-3">
                         <img src={DashboardIcon} className="mb-2" />

                        <DashboardIcon sx={{ color: "#E60E10" }}/>
                        <Typography
                            variant="h6"
                            component="span"
                            className="mx-3"
                            color="#E60E10"
                        >
                            Dashboard
                        </Typography>
                    </div>
                </Link>*/}

        <CardActions className="justify-content-center">
          <Link to={isAdmin ? "/admin-login" : "/login"}>
            <button
              onClick={() => {
                dispatch(adminLogout());
                dispatch(walletNull());
                close();
              }}
              className="lp-btn"
            >
              {/* <img src={Logout} alt='logout'/>  */}
              <i class="fa-solid fa-right-from-bracket"></i>{" "}
              {t("buttons.logout")}
            </button>
          </Link>
        </CardActions>
      </Card>
    );

  return (
    <Card sx={{ minWidth: 275, padding: "20px 0" }} className="light-bg">
      <div className="text-center">
        <IconButton color="inherit" variant="contained">
          <Avatar src={utils.serverImg(image)} />
        </IconButton>
      </div>
      {walletId ? (
        <div className="text-center">
          <Typography variant="h5" component="div">
            Wallet Id
          </Typography>
          <Typography variant="p" component="div">
            {walletId}
          </Typography>
        </div>
      ) : (
        <></>
      )}

      <CardContent className="text-center">
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="p" component="div" fontSize={14}>
          {email}
        </Typography>
      </CardContent>
      {isVerified && (
        <>
          <Link to={"/user/wallet"} style={{ textDecoration: "none" }}>
            <div className="card-wallet d-flex my-1">
              <div>
                <img src={WalletCardIcon} className="mb-2" alt="wallet_card" />
                <Typography
                  variant="p"
                  component="span"
                  className="mx-3"
                  color="#E60E10"
                >
                  {t("buttons.wallet")}
                </Typography>
              </div>
              <div></div>
            </div>
          </Link>

          <div
            style={{ textDecoration: "none" }}
            role="button"
            onClick={() => {
              close();
              toggleProfileModal();
            }}
          >
            <div className="card-wallet d-flex my-1">
              <div>
                <PersonOutlined sx={{ color: "#E60E10" }} />
                <Typography
                  variant="p"
                  component="span"
                  className="mx-3"
                  color="#E60E10"
                >
                  {t("buttons.profile")}
                </Typography>
              </div>
              <div></div>
            </div>
          </div>

          <Link to={"/user/dashboard"} style={{ textDecoration: "none" }}>
            <div className="card-wallet d-flex  my-1">
              {/* <img src={DashboardIcon} className="mb-2" />
               */}
              <DashboardIcon sx={{ color: "#E60E10" }} />
              <Typography
                variant="p"
                component="span"
                className="mx-3"
                color="#E60E10"
              >
                {t("buttons.dashboard")}
              </Typography>
            </div>
          </Link>
        </>
      )}
      <CardActions className="justify-content-center">
        <Link to="/login">
          <button
            onClick={() => {
              dispatch(logout());
              dispatch(walletNull());
              close();
              logoutUser();
            }}
            className="lp-btn"
          >
            {" "}
            {/* <img src={Logout} alt='logout'/>  */}
            <i className="fa-solid fa-right-from-bracket"></i>{" "}
            {t("buttons.logout")}
          </button>
        </Link>
      </CardActions>
    </Card>
  );
}

function Header() {
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileModal, setProfileModal] = useState(false);
  const { i18n, t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const toggleProfileModal = () => setProfileModal((prevState) => !prevState);

  const { user, totalUnseenNotifications, notifications, userDropDown } = useSelector((store) => store.user);
  const { adminUser, totalUnseenAdminNotifications, notificationsAdmin, dropDown } = useSelector((store) => store.admin);
  const { wallet } = useSelector((store) => store.wallet);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popOpen = Boolean(anchorEl);
  const id = popOpen ? "simple-popover" : undefined;

  const language = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("sp");
    }
  }, []);

  const toggleDropdown = () => {
    dispatch(setShowDropdown(!dropDown));
  };

  const toggleUserDropdown = () => {
    dispatch(setUserShowDropdown(!userDropDown));
  };
  const getUserNotification = async () => {
    try {

      setLoader(true)
      const res = await apis.getUserNotification(user?.id)
      if (res?.data?.status) {
        dispatch(setUserNotifications(res?.data?.notifications))
        dispatch(setTotalUnseenNotifications(res?.data?.unSeenNotifications))
      }
      setLoader(false)
    }
    catch (e) {
      setLoader(false)
    }
  }
  const getAdminNotification = async () => {
    try {

      setLoader(true)
      const res = await apis.getAdminNotificationRecieve(adminUser?._id)
      if (res?.data?.status) {
        dispatch(setAdminNotifications(res?.data?.notifications))
        dispatch(setTotalUnseenAdminNotifications(res?.data?.unSeenNotifications))
      }
      setLoader(false)
    }
    catch (e) {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (adminUser) {
      getAdminNotification();
    }
    else if (user) {
      getUserNotification();
    }
  }, [dropDown, adminUser, user, userDropDown])


  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
    navigate("/", { state: { lang: value } });
  };


  return (
    <>
      <header className="custom-header" id="custom-header">
        <div className="logo-section">
          <Link to="/">
            {" "}
            <img src={Logo} alt="logo" className="dashboard-logo" />{" "}
          </Link>
        </div>

        <div
        // className={
        //     isShow ? 'header-main header-show' : 'header-main header-hide'
        // }
        >
          <ul className="header-body">
            {/* {(pathname !== "/en/admin-login" && pathname !== "/sp/admin-login") &&
              <li>
                <div className="language-btn">
                  <button
                    style={{ backgroundColor: language === "en" ? "#e11f1c" : "white" }}

                    className={`${language === "en" ? "active-lng" : "black"}`}
                    onClick={() => handleLanguageChange("en")}
                  >
                    EN
                  </button>
                  <button
                    style={{ backgroundColor: language === "sp" ? "#e11f1c" : "white" }}

                    className={`${language === "sp" ? "active-lng" : "black"}`}
                    onClick={() => handleLanguageChange("sp")}
                  >
                    ES
                  </button>
                </div>
              </li>} */}

            {(() => {
              if (user) {
                return (
                  <li>
                    {totalUnseenNotifications > 0 && <span className="not-top-main-icon">{totalUnseenNotifications}</span>}
                    <MdNotifications onClick={toggleUserDropdown} size={34} className="m-2 black" />
                    {userDropDown && user && (
                      <div
                        className="notification-card-main"

                      >
                        <UserNotification loader={loader} notifications={notifications} unseenCount={totalUnseenNotifications} showDropdown={userDropDown} />
                      </div>
                    )}
                    <IconButton
                      color="inherit"
                      variant="contained"
                      onClick={handleClick}
                    >
                      <Avatar
                        sx={{ border: "1px solid rgba(0,0,0,0.5)" }}
                        src={utils.serverImg(user?.image)}
                      />
                    </IconButton>
                    <Popover
                      // id={id}
                      open={popOpen}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <BasicCard
                        toggleProfileModal={toggleProfileModal}
                        close={handleClose}
                        {...user}
                        walletId={wallet?._id}
                      />
                    </Popover>
                  </li>
                );
              } else if (adminUser) {
                return (
                  <>
                  { adminUser?.type === "owner" &&
                  <>                    {totalUnseenAdminNotifications > 0 && <span className="not-top-main-icon" style={{ top: "-24px", width: 'auto' }}>{totalUnseenAdminNotifications}</span>}
                    <MdNotifications onClick={toggleDropdown} size={34} className="m-2 black" />
                    {dropDown && adminUser?.type === "owner"  && (
                      <div
                        className="notification-card-main"

                      >
                        <UserNotification loader={loader} notifications={notificationsAdmin} unseenCount={totalUnseenAdminNotifications} showDropdown={dropDown} />
                      </div>
                    )}
                    </>

                    }
                    <li>
                      <IconButton
                        color="inherit"
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                      >
                        <Avatar
                          sx={{ border: "1px solid rgba(0,0,0,0.2)" }}
                          src={imageUrl("profileDetails.png")}

                        />
                      </IconButton>
                      <Popover
                        id={id}
                        open={popOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <BasicCard
                          toggleProfileModal={toggleProfileModal}
                          close={handleClose}
                          {...adminUser}
                          walletId={adminUser?.wallet?._id}
                          reserveWalletId={adminUser?.reserveWallet?._id}
                          isAdmin
                        />
                      </Popover>
                    </li>
                  </>
                );
              } else {
                return (
                  <li>
                    {user ? (
                      <></>
                    ) : (
                      <div className="flex-btns" >
                        <button
                          onClick={() => navigate("/login")}
                          className="simple-btn login login-btn-color"
                        >
                          {t("buttons.login")}
                        </button>
                        <button
                          onClick={() => navigate("/registration")}
                          className="lp-btn"
                        >
                          {t("buttons.signup")}
                        </button>
                      </div>
                    )}
                  </li>
                );
              }
            })()}
          </ul>
          {/* <div className="header-bottom">

          </div> */}
        </div>
        {/* <i className="fa-solid fa-bars mobile-show" onClick={setIsShow}></i> */}
        {/* <button className="lp-btn mobile-hide">DOWNLOAD APP</button> */}
        <UserProfileModal
          status={profileModal}
          close={toggleProfileModal}
          user={user}
        />
      </header>
    </>
  );
}

export default Header;
